import React from 'react';

function HomeNewsletter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      fill="none"
      viewBox="0 0 45 44"
    >
      <rect width="44" height="44" x="0.333" fill="#5F39F8" rx="22"></rect>
      <mask
        id="mask0_13547_3980"
        style={{ maskType: 'alpha' }}
        width="25"
        height="24"
        x="10"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M10 10H34V34H10z"></path>
      </mask>
      <g mask="url(#mask0_13547_3980)">
        <path
          fill="#fff"
          d="M29.675 30.375l.7-.7L28.5 27.8V25h-1v3.2l2.175 2.175zM15 31c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0113 29V15c0-.55.196-1.02.588-1.412A1.926 1.926 0 0115 13h14c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v6.7a8.17 8.17 0 00-.975-.387A6.104 6.104 0 0029 21.075V15H15v14h6.05c.05.367.13.717.238 1.05.108.333.237.65.387.95H15zm0-2V15v6.075V21v8zm2-2h4.075c.05-.35.13-.692.238-1.025.108-.333.229-.658.362-.975H17v2zm0-4h6.1c.534-.5 1.13-.917 1.788-1.25A7.044 7.044 0 0127 21.075V21H17v2zm0-4h10v-2H17v2zm11 14c-1.383 0-2.562-.487-3.537-1.462C23.488 30.562 23 29.383 23 28s.488-2.563 1.463-3.538C25.438 23.488 26.617 23 28 23c1.384 0 2.563.488 3.538 1.462C32.513 25.438 33 26.617 33 28s-.487 2.563-1.462 3.538C30.563 32.513 29.384 33 28 33z"
        ></path>
      </g>
    </svg>
  );
}

export default HomeNewsletter;
