import React from 'react';
import PropTypes from 'prop-types';
import {
  LegendBox,
  LegendContainer,
  LegendLabel,
  LegendWrp,
  LegendBoxWpr,
} from './index.sc';
import { colorBox } from '../../graphs/utils/graphConst';

const GraphLegendV2 = ({ legendData = [], mediaType, dashboardType }) => {
  const getTotalValue = (legendData) => {
    return legendData.reduce((total, legend) => {
      if (typeof legend.value === 'number') {
        return total + legend.value;
      } else {
        return total;
      }
    }, 0);
  };

  function filterData(data, mediaTypes) {
    return data.filter((item) => {
      // Convert item label to lowercase for case-insensitive comparison
      const label = item.label.toLowerCase();

      // If mediaType is 'all', include all items
      if (Array.isArray(mediaTypes) && mediaTypes.includes('all')) {
        return true;
      }

      // Check if mediaTypes is an array and if it includes the specific media type
      if (Array.isArray(mediaTypes)) {
        // Filter only the specified media types
        if (
          [
            'online',
            'print',
            'blogs',
            'forums',
            'reviews',
            'reddit',
            'x (twitter)',
            'youtube',
          ].includes(label)
        ) {
          const correctLabel =
            label === 'x (twitter)'
              ? 'twitter'
              : label === 'youtube'
              ? 'YouTube'
              : label;
          return mediaTypes.includes(correctLabel);
        }
      }

      // For other labels, do not filter out
      return true;
    });
  }

  return (
    <LegendWrp>
      {(() => {
        const totalValue = getTotalValue(legendData);

        // Sort legendData in descending order based on the value
        const sortedLegendData = filterData(legendData, mediaType).sort(
          (a, b) => b.value - a.value
        );

        // Handle 'Other' case if more than 4 items
        const visibleLegends =
          dashboardType === 'overview'
            ? sortedLegendData.slice(0, 5)
            : sortedLegendData.slice(0);
        const otherLegends = sortedLegendData.slice(5);

        // const otherValue = otherLegends.reduce(
        //   (sum, legend) => sum + legend.value,
        //   0
        // );
        const legendsToDisplay = [...visibleLegends];

        // // Only add 'Other' if there are more than 4 items
        // if (otherLegends.length > 0) {
        //   legendsToDisplay.push({
        //     label: 'Other',
        //     value: otherValue,
        //     color: '#fffff', // Default color for "Other"
        //   });
        // }

        return legendsToDisplay.map((legend, i) => {
          const formattedValue =
            typeof legend?.value === 'number'
              ? legend?.value.toLocaleString()
              : legend?.value;
          let percentage = (legend?.value / totalValue) * 100;

          if (isNaN(percentage)) {
            percentage = 0;
          }

          return (
            <LegendContainer key={i}>
              <LegendBoxWpr>
                {legend?.color && (
                  <LegendBox bgColor={legend?.color}></LegendBox>
                )}
                <LegendLabel>{legend?.label}</LegendLabel>
              </LegendBoxWpr>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '11rem',
                }}
              >
                <LegendLabel>{`${percentage.toFixed(0)}%`}</LegendLabel>
                <LegendLabel>{formattedValue}</LegendLabel>
              </div>
            </LegendContainer>
          );
        });
      })()}
    </LegendWrp>
  );
};

export default GraphLegendV2;

GraphLegendV2.propTypes = {
  legendData: PropTypes.arrayOf(PropTypes.object),
  mediaType: PropTypes.string,
  dashboardType: PropTypes.string,
};
