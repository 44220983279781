import * as d3 from 'd3';
import RootGraph from '../utils/rootGraph';
import graphBucket from '../utils/graphBucket';
import { initialValues, colorBox, outletBreakDown } from '../utils/graphConst';
import { getGraphUtils } from '../utils/graphUtils';
import { getAccArr } from '../CoolColumn/columnUtils';
import { capitalizeFirstWord } from '../../utils';

class BestBarGraph extends RootGraph {
  setData(data) {
    const inData = JSON.parse(JSON.stringify(data));
    this.labels = inData?.labels
      ? inData?.labels
      : [{ label: 'label', value: 'value' }];

    // this.data = inData?.data;
    const mainData = JSON.parse(JSON.stringify(inData?.data));
    const dataLength = mainData.length || 15;
    this.data = mainData.filter(
      (ele, i) => i < (this.config.maxData || dataLength)
    );

    this.filteredData = this.data;

    const formattedData = [];
    // process data object with multiple values
    if (this.config.isStackedOutlet) {
      const allKeys = new Set();
      this.filteredData.forEach((entry) => {
        Object.keys(entry).forEach((key) => {
          if (key !== 'label') {
            allKeys.add(key);
          }
        });
      });
      const keysList = Array.from(allKeys);

      // For each key, create an array of items
      keysList.forEach((key, keyIndex) => {
        const items = [];

        // Process each entry in the original data
        this.filteredData.forEach((entry, entryIndex) => {
          const value = entry[key] || 0; // Use 0 if the key doesn't exist for this entry

          // Calculate accumulated value
          let previousAccValue = 0;
          if (keyIndex > 0 && formattedData[keyIndex - 1]) {
            previousAccValue =
              formattedData[keyIndex - 1][entryIndex]?.accValue || 0;
          }

          const item = {
            label: entry.label,
            value,
            accValue: previousAccValue + value,
            labelText: key,
            index: entryIndex,
            color: outletBreakDown[entryIndex % colorBox.length],
            lightColor: outletBreakDown[(entryIndex + 1) % colorBox.length],
            rawData: entry,
            labelIndex: keyIndex,
          };

          items.push(item);
        });

        formattedData.push(items);
      });
    } else {
      for (let k = 0; k < this.labels.length; k++) {
        const items = [];
        for (let i = 0; i < this.filteredData.length; i++) {
          const value = this.filteredData[i][this.labels[k].value];
          const item = {
            label: this.config.capitalizeLabel
              ? capitalizeFirstWord(this.filteredData[i].label)
              : this.filteredData[i].label,
            value: parseInt(value),
            accValue:
              (formattedData[k - 1] && formattedData[k - 1][i]?.accValue
                ? formattedData[k - 1][i]?.accValue
                : 0) + parseInt(value),
            labelText: this.labels[k].label,
            index: i,
            color: this.labels[k].color
              ? this.labels[k].color
              : this.data[i].color
              ? this.data[i].color
              : colorBox[(this.labels.length > 1 ? k : i) % colorBox.length],
            lightColor: this.labels[k].lightColor
              ? this.labels[k].lightColor
              : this.data[i].lightColor
              ? this.data[i].lightColor
              : colorBox[(this.labels.length > 1 ? k : i) % colorBox.length],
            labelColor: this.filteredData[i]?.labelColor,
            rawData: this.filteredData[i],
            key: inData?.key,
            // color: this.data[i].color ? this.data[i].color : colorBox[k],
          };
          items.push(item);
        }
        formattedData.push(items);
      }
    }

    this.graphData = formattedData;
  }

  setConfig(configObj = {}) {
    this.config = configObj;
  }

  drawGraph() {
    super.drawGraph();
    const data = this.graphData[0];
    let config = {
      ...initialValues,
      width: this.width,
      height: this.height,
      xAxisType: 'text',
      graphType: 'group1',
      ...this.config,
    };

    const { minY, maxY, graphAreaH, graphAreaW, graphAreaL } = getGraphUtils(
      config,
      config.graphType === 'group'
        ? this.graphData.flat(Infinity)
        : getAccArr(this.graphData)
    );

    const xScale = d3
      .scaleLinear()
      .range([graphAreaW, 0])
      .domain([maxY + (maxY / 100) * 25, minY < 0 ? minY : 0]);

    const xScaleN = d3
      .scaleLinear()
      .range([graphAreaW, 0])
      .domain([maxY + (maxY / 100) * 25, minY < 0 ? minY : 0]);

    const yScale = d3
      .scaleBand()
      .range([config.graphTopPadding, graphAreaH])
      .domain(
        data.map(function (d, i) {
          return d.label;
        })
      );

    config = {
      ...config,
      xScaleN,
      xScale,
      yScale,
      graphAreaH,
      graphAreaW,
      minY,
      maxY,
    };

    // x-axis
    const xAxis = graphBucket.xAxis().config(config);
    this.$graphGrp.datum([data]).call(xAxis);

    // y-axis
    const yAxis = graphBucket.yAxis().config(config);
    this.$graphGrp.datum([data]).call(yAxis);

    //  rect
    let rects;

    if (config.isStackedOutlet) {
      rects = graphBucket.outletRect().config(config);
    } else {
      rects = graphBucket.barRect().config(config);
    }

    const rectGrps = this.$graphGrp.selectAll('.barRectGroup').node()
      ? this.$graphGrp.selectAll('.barRectGroup')
      : this.$graphGrp.append('g');

    rectGrps
      .attr('class', 'barRectGroup')
      .attr('transform', 'translate(' + graphAreaL + ',0)')
      .datum(this.graphData)
      .call(rects);
  }

  onResetFunc() {
    const classSelected = this.$graphGrp.selectAll('.column-rect');
    const classSelected2 = this.$graphGrp.selectAll('.bar-label');
    classSelected.classed('selected', false);
    classSelected.classed('unselected', false);
    classSelected2.classed('selected', false);
    classSelected2.classed('unselected', false);
  }
}

export default BestBarGraph;
