import React from 'react';
import SearchAlphaAIInsights from '../alpha-ai-insights';
import PropTypes from 'prop-types';
import { ErrorText, PopupStyledMarkdown, PopupTxtWpr } from './index.sc';
import AiLoader from '../ai-loader';

const SearchAIInsightsPopup = ({
  popup,
  setInsightType,
  insightType,
  data,
  togglePopup,
  setShowAIInsight,
  isLoading = false,
  isSuccessful = true,
}) => {
  return (
    <div>
      <SearchAlphaAIInsights
        popup={true}
        setInsightType={setInsightType}
        setShowAIInsight={setShowAIInsight}
      />
      <PopupTxtWpr>
        {isLoading ? (
          <AiLoader />
        ) : isSuccessful ? (
          <PopupStyledMarkdown>{data}</PopupStyledMarkdown>
        ) : (
          <ErrorText>
            An error occurred while processing the data. Please try again later
          </ErrorText>
        )}
      </PopupTxtWpr>
    </div>
  );
};

export default SearchAIInsightsPopup;

SearchAIInsightsPopup.propTypes = {
  popup: PropTypes.bool,
  setInsightType: PropTypes.func,
  insightType: PropTypes.string,
  data: PropTypes.func,
  togglePopup: PropTypes.func,
  setShowAIInsight: PropTypes.func,
  isLoading: PropTypes.bool,
  isSuccessful: PropTypes.bool,
};
