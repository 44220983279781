import { blueColorGradients } from '../../constants/graph-colors';

export const mockHeatData = {
  brand1: {
    data: [
      {
        label: 'Jan 1',
        value: 3259,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 2',
        value: 2201,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 3',
        value: 1000,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 4',
        value: 1000,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 5',
        value: 1000,
        color: blueColorGradients.blue60,
      },
      {
        label: 'Jan 6',
        value: 1000,
        color: blueColorGradients.blue50,
      },
      {
        label: 'Jan 7',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 8',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 9',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 10',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 11',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 12',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 13',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 14',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 15',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 16',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 17',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 18',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 19',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 20',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 21',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 22',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 23',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 24',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 25',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 26',
        value: 10,
        color: blueColorGradients.blue40,
      },
    ],
    prImpact: 95,
  },
  brand2: {
    data: [
      {
        label: 'Jan 1',
        value: 3259,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 2',
        value: 2201,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 3',
        value: 1000,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 4',
        value: 1000,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 5',
        value: 1000,
        color: blueColorGradients.blue60,
      },
      {
        label: 'Jan 6',
        value: 1000,
        color: blueColorGradients.blue50,
      },
      {
        label: 'Jan 7',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 8',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 9',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 10',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 11',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 12',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 13',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 14',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 15',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 16',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 17',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 18',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 19',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 20',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 21',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 22',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 23',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 24',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 25',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 26',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 27',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 28',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 29',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 30',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 31',
        value: 10,
        color: blueColorGradients.blue40,
      },
    ],
    prImpact: 95,
  },
  brand3: {
    data: [
      {
        label: 'Jan 1',
        value: 3259,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 2',
        value: 2201,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 3',
        value: 1000,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 4',
        value: 1000,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 5',
        value: 1000,
        color: blueColorGradients.blue60,
      },
      {
        label: 'Jan 6',
        value: 1000,
        color: blueColorGradients.blue50,
      },
      {
        label: 'Jan 7',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 8',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 9',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 10',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 11',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 12',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 13',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 14',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 15',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 16',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 17',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 18',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 19',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 20',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 21',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 22',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 23',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 24',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 25',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 26',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 27',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 28',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 29',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 30',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 31',
        value: 10,
        color: blueColorGradients.blue40,
      },
    ],
    prImpact: 95,
  },
  brand4: {
    data: [
      {
        label: 'Jan 1',
        value: 3259,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 2',
        value: 2201,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 3',
        value: 1000,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 4',
        value: 1000,
        color: blueColorGradients.blue70,
      },
      {
        label: 'Jan 5',
        value: 1000,
        color: blueColorGradients.blue60,
      },
      {
        label: 'Jan 6',
        value: 1000,
        color: blueColorGradients.blue50,
      },
      {
        label: 'Jan 7',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 8',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 9',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 10',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 11',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 12',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 13',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 14',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 15',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 16',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 17',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 18',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 19',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 20',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 21',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 22',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 23',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 24',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 25',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 26',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 27',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 28',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 29',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 30',
        value: 10,
        color: blueColorGradients.blue40,
      },
      {
        label: 'Jan 31',
        value: 10,
        color: blueColorGradients.blue40,
      },
    ],
    prImpact: 95,
  },
};
