import {
  purpleColorGradients,
  magentaColorGradients,
  cyanColorGradients,
  tealColorGradients,
  blueColorGradients,
  orangeColorGradients,
  yellowColorGradients,
  greenColorGradients,
} from '../../constants/graph-colors';
import { theme } from '../../constants/theme';

export const initialValues = {
  x: undefined,
  y: undefined,
  width: 0,
  height: 0,
  fontSize: 11,
  padding: { left: 0, top: 0, right: 32, bottom: 0 },
  textwrap: false,
  xLabelAlignment: 25,
  yLabelAlignment: 35,
  xAxisLabelBreatingSpace: 12,
  duration: 1000,
  gutterSpace: 10,
  graphTopPadding: 10,
  dasharray: 4,
  gridLineStrokeWidth: 2,
};

export const colorBox = [
  '#614CFF',
  '#8A74FF',
  '#D16BFD',
  '#9955F4',
  '#4337A0',
  '#85ECE9',
  '#32CBC8',
  '#35C684',
  '#FA8750',
  '#EFB73E',
  '#FFE566',
  '#EB4A84',
  '#6D5E7B',
  '#656B8A',
  '#B4BAD9',
];

export const outletBreakDown = [
  purpleColorGradients.purple60,
  magentaColorGradients.magenta50,
  blueColorGradients.blue60,
  tealColorGradients.teal60,
  yellowColorGradients.yellow40,
  cyanColorGradients.cyan50,
  greenColorGradients.green50,
  magentaColorGradients.magenta70,
];

export const topThemeColors = [
  magentaColorGradients.magenta60, // '#D02670'
  purpleColorGradients.purple60, // '#893FFC'
  cyanColorGradients.cyan50, // '#1192E8'
  tealColorGradients.teal50, // '#009D9A'
  orangeColorGradients.orange50, // '#EB6200'
  blueColorGradients.blue50, // '#4689FF'
];
export const CampaignMonitor = [
  magentaColorGradients.magenta60, // '#D02670'
  purpleColorGradients.purple60, // '#893FFC'
  cyanColorGradients.cyan50, // '#1192E8'
  tealColorGradients.teal50, // '#009D9A'
  orangeColorGradients.orange50, // '#EB6200'
  blueColorGradients.blue50, // '#4689FF'
];

export const concentricPie = [
  tealColorGradients.teal50, // '#009D9A'
  purpleColorGradients.purple60, // '#893FFC'
  magentaColorGradients.magenta50, // '#EE5396'
];

export const borderColor = [
  '#4335b2',
  '#6051b2',
  '#924ab1',
  '#6d3d9c',
  '#2f296e',
  '#5db4a8',
  '#238a7a',
  '#248a5c',
  '#a4335c',
  '#a47f2a',
  '#a48a3c',
  '#a4335c',
  '#4c4156',
  '#464a60',
  '#7d8297',
];

export const sovColorBox = [
  theme?.light?.graphColors?.purple60,
  theme?.light?.graphColors?.magenta60,
  theme?.light?.graphColors?.cyan50,
  theme?.light?.graphColors?.orange50,
  theme?.light?.graphColors?.teal60,
  theme?.light?.graphColors?.yellow40,
  '#6929C5',
  // '#1292E9',
  // '#005E5D',
  // '#9F1852',
  // '#FA4E56',
];

export const lightGradientSOVColorBox = [
  theme?.light?.graphColors?.purple40,
  theme?.light?.graphColors?.magenta40,
  theme?.light?.graphColors?.cyan30,
  theme?.light?.graphColors?.orange30,
  theme?.light?.graphColors?.teal40,
  theme?.light?.graphColors?.yellow30,

  '#6929C5',
];

export const randomColor = [
  theme?.light?.graphColors?.purple20,
  theme?.light?.graphColors?.orange20,
  theme?.light?.graphColors?.red20,
  theme?.light?.graphColors?.teal20,
  theme?.light?.graphColors?.green20,
  theme?.light?.graphColors?.magenta20,
  theme?.light?.graphColors?.coolGray20,
  theme?.light?.graphColors?.blue20,
  theme?.light?.graphColors?.cyan20,
  theme?.light?.graphColors?.yellow20,
];
