import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  tabsData: [
    {
      campaign_id: uuidv4(),
      name: 'Campaign 1',
      keywords: '',
    },
  ],
  activeTab: 0,
  comparisonFilter: {
    dateTime: {
      label: 'Last 6 Months',
      value: 'last_6_months',
    },
  },
};

const campaignMonitor = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setTabData: (state, action) => {
      const { tabIndex, data } = action.payload;
      if (state.tabsData[tabIndex]) {
        state.tabsData[tabIndex] = { ...state.tabsData[tabIndex], ...data };
      } else {
        state.tabsData[tabIndex] = data;
      }
    },
    // updateLaunchTimeline: (state, action) => {
    //   const { tabIndex, weightage } = action.payload;
    //   if (state.tabsData[tabIndex]) {
    //     state.tabsData[tabIndex].weightage = {
    //       ...state.tabsData[tabIndex].weightage,
    //       ...weightage,
    //     };
    //   }
    // },
    addNewTabData: (state, action) => {
      //   console.log('it is executing');
      const newTab = {
        campaign_id: uuidv4(),
        name: `Campaign ${state.tabsData.length + 1}`,
        keywords: '',
        ...action.payload,
      };
      state.tabsData.push(newTab);
      state.activeTab = state.tabsData.length - 1;
    },
    removeTabData: (state, action) => {
      const tabIndex = action.payload;
      state.tabsData.splice(tabIndex, 1);
      if (state.activeTab >= state.tabsData.length) {
        state.activeTab = Math.max(0, state.tabsData.length - 1);
      }
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateTabName: (state, action) => {
      const { tabIndex, name } = action.payload;
      if (state.tabsData[tabIndex]) {
        state.tabsData[tabIndex].name = name;
      }
    },
    resetTabData: () => {
      return initialState;
    },
    setComparisonFilter: (state, action) => {
      state.comparisonFilter = action?.payload;
    },
  },
});

export const {
  setTabData,
  addNewTabData,
  removeTabData,
  setActiveTab,
  updateTabName,
  resetTabData,
  setComparisonFilter,
} = campaignMonitor.actions;
export default campaignMonitor.reducer;
