import styled, { keyframes } from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  /* min-width: max-content; */
  width: ${(props) => (props.newWidth ? `${props.newWidth}rem` : '100%')};
  height: ${(props) => (props.newHeight ? `${props.newHeight}rem` : ' 2.6rem')};
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: white;
  border: 1px solid #dedede;
  padding: 1px;
  /* min-width: 0; */

  /* padding: 0.4375rem 0.5rem; */
`;

export const SelectOptionComponent = styled.button`
  width: 100%;
  height: 100%;
  /* padding: 8px 15px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.7rem;
  border-radius: 0.375rem;
  border: none;
  background: #fff;
`;

export const DropdownOptionsList = styled.ul`
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: ${({ bottom }) => (bottom ? '' : '2.5rem')};
  bottom: ${({ bottom }) => bottom && '2.5rem'};
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  list-style-type: none;
  padding: 0;
  padding-top: 0.375rem;
  padding-bottom: 0.625rem;
  margin: 0;
  width: 100%;
  min-width: max-content;
  max-height: 14.5rem;
  display: ${({ open }) => (open ? 'block' : 'none')};
  margin-left: ${({ dateOptions }) => dateOptions && '15rem'};
`;

export const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.44rem;
  white-space: nowrap;
  color: ${({ theme, grayedOut }) =>
    !grayedOut ? theme.secondaryText : 'gray'};
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
  width: 98%;
  text-overflow: ellipsis;
`;

export const DropdownList = styled.ul`
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  /* border: 1px solid #000000; */
  border-radius: 0.375rem;
  list-style-type: none;
  padding: 0;
  padding-top: 0;
  padding-bottom: 0.625rem;
  margin: 0;
  width: 100%;
  min-width: 15rem;
  max-height: 50vh;
  display: 'block';
  &.open-from-left {
    left: 0;
  }
  &.open-from-right {
    right: 0;
  }
`;

export const DropdownListItem = styled.li`
  height: 2rem;
  padding: 0.5rem 0.625rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.813rem;
  &.dropdown-search {
    height: 1.875rem;
    padding: 0rem 0.625rem;
  }
  &:hover {
    color: ${({ theme }) => theme.primary};
    span {
      color: ${({ theme }) => theme.primary};
    }
    background-color: ${({ theme }) => theme.addContent};
    border-radius: 5px;
  }
  &:hover .hover-override {
    color: initial !important; /* or specify a different color or none */
  }
`;

export const OptionTitle = styled.span`
  font-size: 0.813rem;
  line-height: 1rem;
`;

export const SearchInput = styled.input`
  padding: 10px;
  width: 98%;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin: 5px;
  box-sizing: border-box;
  position: sticky;
  top: 0;

  &:focus {
    border-color: #ced4da;
    outline: none;
  }
`;
