import React from 'react';
import PropTypes from 'prop-types';

function RefreshIcon({ color = 'rgb(92, 94, 96)', width = '15' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      fill={color}
      stroke={color}
      transform="scale(-1 1)"
      version="1.1"
      viewBox="0 0 367.14 367.14"
      xmlSpace="preserve"
    >
      <path d="M336.554 86.871a182.443 182.443 0 00-44.706-47.731L330.801 0H217.436v113.91L270.4 60.691c40.142 28.131 65.042 74.724 65.042 124.571 0 83.744-68.13 151.874-151.874 151.874S31.694 269.005 31.694 185.262c0-58.641 32.781-111.009 85.551-136.669l-13.119-26.979C73.885 36.318 48.315 59.1 30.182 87.494c-18.637 29.184-28.488 62.991-28.488 97.768 0 100.286 81.588 181.874 181.874 181.874s181.874-81.588 181.874-181.874c0-35.039-9.989-69.061-28.888-98.391z"></path>
    </svg>
  );
}

export default RefreshIcon;

RefreshIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
};
