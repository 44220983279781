import Markdown from 'react-markdown';
import styled from 'styled-components';
const SlotBodyHeight = 'calc(100% - 1.5rem) '; // -1*2 added for the gap value
const SlotDetailsWrpGap = 0;

export const TopInfluencerTableWrp = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SlotDetailsWrpGap}rem;
`;

export const TopInfluencerTableHdr = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.3125rem 0 0.3125rem 0.3125rem;
  width: 100%;
  /* background-color: ${({ theme }) => theme?.graphColors?.coolGray30}; */
  border-bottom: ${({ theme }) =>
    `1px solid ${theme?.graphColors?.coolGray20}`};
`;

export const TopInfluencerHeaderTitle = styled.div`
  display: flex;
  font-family: Inter;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  gap: 5px;
  line-height: 0.875rem;
  letter-spacing: -0.01em;
  max-width: ${({ index }) => (index ? '8rem' : 'auto')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: ${({ align }) => align};
  color: ${({ theme }) => theme?.text};
  width: 100%;
`;

export const TopInfluencerTableBodyWrp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TopInfluencerTableBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  height: 1.78rem;
  padding: 0;
  cursor: pointer;
  width: 100%;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme?.graphColors?.coolGray20}`};
  &:hover {
    opacity: 0.7;
    transition: background-color 0.2s ease-in-out;
  }
`;

export const TopInfluencerLogoTextWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  width: 100%;
  align-items: center;
`;
export const TopInfluencerIconWrp = styled.div``;
export const TopInfluencerLogo = styled.img`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.25rem;
`;

export const TopInfluencerBodyText = styled.div`
  background: ${({ background }) => background ?? '#fff'};
  padding-left: ${({ background }) => (background ? '5px' : '0px')};
  font-family: Inter;
  display: flex;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: -0.01em;
  max-width: ${({ index }) => (index ? '8rem' : 'auto')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: ${({ align }) => align};
  align-items: center;
  color: ${({ theme }) => theme?.graphColors?.coolGray70};
  width: 100%;
`;

export const TopInfluencerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TopInfluencerHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
`;
export const TopInfluencerHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: left;
`;

export const TopInfluencerTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: #161a34;
  /* width: 95%; */
  width: fit-content;
  line-height: 1.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  height: min-content;
  align-items: center;
`;

export const IconBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
`;

export const TopInfluencerSummaryWrapper = styled.div`
  width: fit-content;
`;

export const Iconwpr = styled.div`
  cursor: pointer;
`;

export const TopSlotBody = styled.div`
  position: relative;
  height: ${({ type }) => SlotBodyHeight};
  width: 100%;
  flex-grow: 1;
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  // height: 14rem;
  overflow-y: scroll;
`;
export const TopSlotHeader = styled.div`
  position: relative;
  height: ${({ type }) => SlotBodyHeight};
  width: 100%;
  flex-grow: 1;
  padding: 0 1.25rem;
  display: flex;
  flex-direction: row;
  height: 1.75rem;
  align-items: center;
`;

export const StyledMarkdown = styled(Markdown)`
  font-family: Inter;
  text-align: start;
  color: ${({ theme }) => theme.graphColors.coolGray70};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-top: 0.5rem;
`;

export const TopCommentarySection = styled.div`
  width: 90%;
  margin-left: 1.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const TopInfluencerHeaderWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  width: 100%;
  align-items: center;
`;

export const GraphNoDataText = styled.h1`
  margin-top: 5rem;
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.disabledBtnColor};
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center !important;
`;

export const SubTitleSmallWrp = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray60};
`;

export const FilterError = styled.div`
  color: #343a3f;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
