import React from 'react';

function HomeDashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      fill="none"
      viewBox="0 0 45 44"
    >
      <rect width="44" height="44" x="0.667" fill="#5F39F8" rx="22"></rect>
      <mask
        id="mask0_13547_18"
        style={{ maskType: 'alpha' }}
        width="25"
        height="24"
        x="10"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M10.334 10H34.334V34H10.334z"></path>
      </mask>
      <g mask="url(#mask0_13547_18)">
        <path
          fill="#fff"
          d="M17.733 32l-1.4-1.4 6.9-6.925 3.5 3.5L31.909 22l1.425 1.425-6.6 6.575-3.5-3.5-5.5 5.5zm-3.4-1c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0112.334 29V15c0-.55.195-1.02.587-1.412A1.926 1.926 0 0114.334 13h14c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412v4.2h-16V31zm0-13.8h14V15h-14v2.2z"
        ></path>
      </g>
    </svg>
  );
}

export default HomeDashboardIcon;
