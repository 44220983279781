import React from 'react';

function HomeAlerts() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      fill="none"
      viewBox="0 0 45 44"
    >
      <rect width="44" height="44" x="0.667" fill="#5F39F8" rx="22"></rect>
      <mask
        id="mask0_13547_4036"
        style={{ maskType: 'alpha' }}
        width="25"
        height="24"
        x="10"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M10.334 10H34.334V34H10.334z"></path>
      </mask>
      <g mask="url(#mask0_13547_4036)">
        <path
          fill="#fff"
          d="M12.334 27c.833 0 1.541.292 2.125.875.583.583.875 1.292.875 2.125h-3v-3zm0-4c1.95 0 3.604.68 4.962 2.038 1.358 1.358 2.038 3.012 2.038 4.962h-2c0-1.383-.488-2.563-1.463-3.538-.975-.974-2.154-1.462-3.537-1.462v-2zm0-4c1.516 0 2.941.288 4.274.863 1.334.575 2.5 1.362 3.5 2.362s1.788 2.167 2.363 3.5A10.68 10.68 0 0123.334 30h-2c0-1.25-.238-2.42-.713-3.512a9.148 9.148 0 00-1.925-2.85 9.148 9.148 0 00-2.85-1.926A8.707 8.707 0 0012.334 21v-2zm18 11h-5a13.366 13.366 0 00-.15-2h5.15v-5.3a8.338 8.338 0 001.05-.412c.333-.159.65-.346.95-.563V28c0 .55-.196 1.02-.588 1.413a1.926 1.926 0 01-1.412.587zm-18-13v-1c0-.55.195-1.02.587-1.412A1.926 1.926 0 0114.334 14h7.3c-.1.317-.175.642-.226.975-.05.333-.075.675-.075 1.025h-7v1.15a13.592 13.592 0 00-2-.15zm16 4c-1.384 0-2.563-.488-3.538-1.462-.975-.976-1.462-2.155-1.462-3.538s.487-2.563 1.462-3.537C25.771 11.488 26.95 11 28.334 11c1.383 0 2.562.488 3.537 1.463.975.975 1.463 2.154 1.463 3.537s-.488 2.563-1.463 3.538c-.975.974-2.154 1.462-3.537 1.462zm0-2a.48.48 0 00.35-.15.48.48 0 000-.7.48.48 0 00-.7 0 .48.48 0 000 .7c.1.1.216.15.35.15zm-.5-2h1v-4h-1v4z"
        ></path>
      </g>
    </svg>
  );
}

export default HomeAlerts;
