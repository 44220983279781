import styled, { keyframes, css } from 'styled-components/macro';
import { ArticleSection } from '../search-result/index.sc';

export const fullWidth = 100;
const margin = 1.5;
export const gap = 0.5;
export const dashboardWidth = 65 - margin;
export const articleWdth = fullWidth - dashboardWidth - margin;
export const buttonWidth = 1.37;

export const SearchMainWrp = styled.div`
  height: 100%;
  /* overflow: hidden; */
  &.p-relative {
    position: relative;
  }

  /* &::-webkit-scrollbar {
    display: none;
  } */
`;

export const BtnWrp = styled.div`
  position: absolute;
  overflow: hidden;
  top: ${({ top = '8.5rem' }) => top};
  box-shadow: 0px 5px 10px rgba(108, 73, 172, 0.1);
  left: ${({ activeScreen = '' }) =>
    activeScreen === 'dashboard'
      ? `${fullWidth - buttonWidth}vw`
      : activeScreen === 'article'
      ? `${-1.1 * buttonWidth}vw`
      : `${dashboardWidth + gap - 1 / 2}vw`};
  background-color: #fff;
  height: 1.875rem;
  width: 2.75rem;
  border: 2px solid #675ef2;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const Btn = styled.button`
  border: none;
  width: ${`${buttonWidth}rem`};
  height: 105%;
  padding: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &.inactive {
    background-color: #675ef2;
    color: #fff;
  }

  &:hover > svg > path {
    stroke: #fff;
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  background: ${({ background }) => background || '#e56317'};
  width: 100%;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  justify-content: space-between;
`;

export const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  margin-right: 10px;
  color: #ffffff;
  font-size: 12px;
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;

    &:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: rgba(
      255,
      255,
      255,
      0.4
    ); /* Matches the container background color */
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }

  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
export const Button = styled.button`
  padding: 5px 5px;
  border-radius: 3px;
  background-color: ${({ background }) => background || '#ea8245'};
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: ${({ background }) => (background ? '0.75rem' : '0.8125rem')};
  font-weight: 600;
  letter-spacing: unset;

  span {
    margin-right: 5px;
  }
`;

export const SearchResultWrp = styled.div`
  /* height: calc(100vh - 11.5rem); */
  height: 100%;
  transform: ${({ activeScreen = '' }) =>
    activeScreen === 'article'
      ? `translateX(${-1 * (dashboardWidth + margin * 2)}vw)`
      : 'none'};
  display: flex;
  padding: 0 1.5rem;
  position: relative;
  gap: ${({ activeScreen = '' }) =>
    activeScreen !== '' ? `${margin * 2}rem` : `${gap}rem`};
  transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const scaleInAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const scaleOutAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
`;

export const ArticleSectionComponentWrp = styled.div`
  position: fixed;
  width: 42rem;
  height: 88.25vh;
  top: 8%;
  display: flex;
  gap: 0.5rem;
  z-index: 20;

  ${({ articlePosition }) =>
    articlePosition === 'left'
      ? css`
          animation: ${scaleInAnimation} 500ms ease;
          left: calc(50% - (4.5rem));
          flex-direction: row-reverse;
        `
      : articlePosition === 'right'
      ? css`
          animation: ${scaleInAnimation} 500ms ease;
          right: calc(50% - (4.5rem));
          flex-direction: row;
        `
      : css`
          transition: opacity 400ms ease-in, transform 10ms 400ms ease-in,
            flex-direction 10ms 400ms ease-in, right 10ms 400ms ease-in;
          opacity: 0;
          right: 100%;
          animation: ${scaleOutAnimation} 500ms ease;
          flex-direction: row;
        `}
  ${({ activeScreen }) =>
    activeScreen !== '' &&
    `
    ${ArticleSection} {
      padding: 0.25rem 0;
    }
  `};
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.overlay};
  z-index: 11;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  left: ${({ articlePosition }) =>
    articlePosition === 'left' ? 'calc(50%)' : 'calc(50% - 1.25rem)'};
  z-index: 10;
  background-color: #fff;
  border-radius: 100%;
`;
