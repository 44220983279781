// index.sc.js
import styled from 'styled-components';

// Base styles for Tooltip labels and values
export const TooltipDate = styled.div`
  font-weight: 600;
  color: #4d5358;
  font-size: 13px;
`;
export const TooltipLabel = styled.div`
  font-weight: 600;
  color: ${({ color }) => color ?? ' #4d5358'};
  font-size: 12px;
`;

export const TooltipValue = styled.div`
  margin-top: 5px;
  font-weight: 600;
  font-size: 13px;
  color: #000000;
`;

// Tooltip One
export const TooltipOneContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 8px 20px 0px #0000001f;
`;

// Tooltip Two
export const TooltipTwoContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 8px 20px 0px #0000001f;
`;

// Tooltip Three
export const TooltipThreeContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 8px 20px 0px #0000001f;
`;

// Tooltip Four
export const TooltipFourContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 8px 20px 0px #0000001f;
`;

// Tooltip Five
export const TooltipFiveContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 8px 20px 0px #0000001f;
`;
