import React from 'react';
import Proptypes from 'prop-types';
import { LegendBox, LegendContainer, LegendLabel, LegendWrp } from './index.sc';
import { outletBreakDown as colorBox } from '../../graphs/utils/graphConst';

const GraphLegendOutlet = ({ legendData = [] }) => {
  const formatLegendData = (data) => {
    return data.map((item) => {
      // Get all keys except 'label'
      const keys = Object.keys(item).filter((key) => key !== 'label');

      // Create value array with each key-value pair
      const value = keys.map((key) => ({
        label: key,
        value: item[key],
      }));

      return {
        label: item.label,
        value,
      };
    });
  };

  const formattedData = formatLegendData(legendData);

  return (
    <LegendWrp>
      <LegendContainer>
        {formattedData?.map((legend, i) => (
          <React.Fragment key={`legend-${legend?.label}-${i}`}>
            {legend?.value?.map((subLegend, j) => (
              <React.Fragment
                key={`sublegend-${legend?.label}-${subLegend?.label}-${j}`}
              >
                <LegendBox
                  bgColor={colorBox[i % colorBox.length]}
                  opacity={j}
                />
                <LegendLabel>
                  {subLegend?.label}:{' '}
                  {subLegend?.value?.toLocaleString('en-US')}
                </LegendLabel>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </LegendContainer>
    </LegendWrp>
  );
};

GraphLegendOutlet.propTypes = {
  legendData: Proptypes.array,
};

export default GraphLegendOutlet;
