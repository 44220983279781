import * as d3 from 'd3';
import { concentricPie } from './concentricCircle';

class ConcentricPieGraph {
  constructor(element) {
    this.$graphWrp = element;
    this.data = null;
    this.config = {};
    this.width = element.clientWidth;
    this.height = element.clientHeight;
  }

  setData(inData) {
    this.data = JSON.parse(JSON.stringify(inData));
  }

  setConfig(configObj = {}) {
    this.config = configObj;
  }

  drawGraph() {
    const data = this.data;
    const config = {
      width: this.width,
      height: this.height,
      ...this.config,
    };

    const chart = concentricPie().config(config);

    d3.select(this.$graphWrp)
      .selectAll('.concentric-pie-chart')
      .data([data])
      .join('div')
      .attr('class', 'concentric-pie-chart')
      .call(chart);
  }

  onResetFunc() {
    const allGroups = d3
      .select(this.$graphWrp)
      .selectAll('g.secondary-group, g.primary-group');
    allGroups.classed('selected', false).classed('unselected', false);
  }
}

export default ConcentricPieGraph;
