import styled from 'styled-components';

export const CustomizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const CustomizeHeaderWrp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CustomizeHeaderDespWrp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CustomizeHeader = styled.div`
  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.text};
`;

export const CustomizeDescp = styled.div`
  font-family: Inter;
  font-size: ${({ size }) => size || '0.8125rem'};
  font-weight: 500;
  line-height: 1.125rem;
  text-align: left;
  color: ${({ theme }) => theme?.errorPageText};
`;

export const CustomizeIconWrp = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

export const CustomizeContentWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  padding: 1rem 0;
`;

export const CustomizeAddWrp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  height: 170px;
`;

export const CustomizeBrandContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  height: 100%;
`;

export const CustomizeBrandHeader = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.text};
`;

export const CustomizeInputWrp = styled.div`
  width: 100%;
  height: 2.75rem;
  padding: 0.375rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.shadow};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CustomizeInputIconWrp = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  align-items: center;
  user-select: none;
`;

export const CustomizeIncIcon = styled.div`
  width: 1rem;
  height: 1rem;
  text-align: center;
  cursor: pointer;
`;

export const CustomizeBrandInput = styled.input`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.text};
  border: none;
  outline: none;
  width: 100%;
`;

export const CustomizeAlertContainer = styled.div`
  width: 100%;
  height: 36px;
  padding: 0.5rem;
  gap: 0.375rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ borderColor }) => borderColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
`;

export const CustomizeAlertMsg = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.errorPageText};
`;

export const CustomizeButtonWrp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CustomizeBtnWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;
