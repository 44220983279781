import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabsData: [
    {
      campaign_id: new Date().getTime(),
      name: 'Campaign 1',
      keywords: '',
      launch_timeline: {
        pre_start_date: null,
        pre_end_date: null,
        during_start_date: null,
        during_end_date: null,
        post_start_date: null,
        post_end_date: null,
      },
    },
  ],
  activeTab: 0,
};

const advancedDashboardTabSlice = createSlice({
  name: 'advancedDashboardTab',
  initialState,
  reducers: {
    setTabData: (state, action) => {
      const { tabIndex, data } = action.payload;
      if (state.tabsData[tabIndex]) {
        state.tabsData[tabIndex] = { ...state.tabsData[tabIndex], ...data };
      } else {
        state.tabsData[tabIndex] = data;
      }
    },
    updateLaunchTimeline: (state, action) => {
      const { tabIndex, launchTimeline } = action.payload;
      if (state.tabsData[tabIndex]) {
        state.tabsData[tabIndex].launch_timeline = {
          ...state.tabsData[tabIndex].launch_timeline,
          ...launchTimeline,
        };
      }
    },
    addNewTabData: (state, action) => {
      const newTab = {
        campaign_id: new Date().getTime(),
        name: `Campaign ${state.tabsData.length + 1}`,
        keywords: '',
        launch_timeline: {
          pre_start_date: null,
          pre_end_date: null,
          during_start_date: null,
          during_end_date: null,
          post_start_date: null,
          post_end_date: null,
        },
        ...action.payload,
      };
      state.tabsData.push(newTab);
      state.activeTab = state.tabsData.length - 1;
    },
    removeTabData: (state, action) => {
      const tabIndex = action.payload;
      state.tabsData.splice(tabIndex, 1);
      if (state.activeTab >= state.tabsData.length) {
        state.activeTab = Math.max(0, state.tabsData.length - 1);
      }
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateTabName: (state, action) => {
      const { tabIndex, name } = action.payload;
      if (state.tabsData[tabIndex]) {
        state.tabsData[tabIndex].name = name;
      }
    },
    resetTabData: () => {
      return initialState;
    },
  },
});

export const {
  setTabData,
  updateLaunchTimeline,
  addNewTabData,
  removeTabData,
  setActiveTab,
  updateTabName,
  resetTabData,
} = advancedDashboardTabSlice.actions;
export default advancedDashboardTabSlice.reducer;
