// index.js
import React from 'react';
import PropTypes from 'prop-types';
import {
  TooltipOneContainer,
  TooltipTwoContainer,
  TooltipThreeContainer,
  TooltipFourContainer,
  TooltipFiveContainer,
  TooltipLabel,
  TooltipValue,
  TooltipDate,
} from './index.sc';
import {
  blueColorGradients,
  coolGrayColorGradients,
  greenColorGradients,
  purpleColorGradients,
  redColorGradients,
} from '../../../../constants/graph-colors';
import { formatNumber } from '../../../../utils';
import { addCountPrefix } from '../../../../constants/utils';

const SocialTooltip = ({ tooltipData, widget }) => {
  const renderTooltip = () => {
    switch (widget.customClassName) {
      case 'advanced_campaign_monitor_reach':
        return (
          <TooltipOneContainer>
            <TooltipDate>{tooltipData?.data?.label}</TooltipDate>
            <TooltipValue>{formatNumber(tooltipData?.data.gucci)}</TooltipValue>
          </TooltipOneContainer>
        );

      case 'advanced_campaign_monitor_themes':
        return (
          <TooltipTwoContainer>
            <TooltipLabel color={tooltipData?.data?.color}>
              {tooltipData?.data?.label}
            </TooltipLabel>
            <TooltipValue>{tooltipData?.data?.accValue}</TooltipValue>
          </TooltipTwoContainer>
        );

      case 'advanced_campaign_monitor_sentiment_over_time':
        return (
          <TooltipThreeContainer>
            <div>
              <TooltipLabel color={greenColorGradients.green60}>
                Positive
              </TooltipLabel>
              <TooltipValue>{tooltipData?.data?.POS}</TooltipValue>
            </div>
            <div>
              <TooltipLabel color={redColorGradients.red60}>
                Negative
              </TooltipLabel>
              <TooltipValue>{tooltipData?.data?.NEG}</TooltipValue>
            </div>
            <div>
              <TooltipLabel color={coolGrayColorGradients.coolGray60}>
                Neutral
              </TooltipLabel>
              <TooltipValue>{tooltipData?.data?.NEU}</TooltipValue>
            </div>
          </TooltipThreeContainer>
        );

      case 'advanced_campaign_monitor_wordcloud':
        return (
          <TooltipTwoContainer>
            <TooltipLabel color={tooltipData?.data?.labelColor}>
              {tooltipData?.data?.label}
            </TooltipLabel>
            <TooltipValue>{tooltipData?.data?.value}</TooltipValue>
          </TooltipTwoContainer>
        );

      case 'advanced_campaign_monitor_top_author':
        return (
          <TooltipFiveContainer>
            <TooltipLabel color={blueColorGradients.blue60}>
              {tooltipData?.data?.label}
            </TooltipLabel>
            <TooltipValue>{tooltipData?.data.accValue}</TooltipValue>
          </TooltipFiveContainer>
        );

      case 'advanced_campaign_comparison_reach_by_campaign':
        return (
          <TooltipFiveContainer>
            {Object.keys(tooltipData?.data).map((key) => {
              if (key !== 'label' && key !== 'value') {
                return (
                  <React.Fragment key={key}>
                    <TooltipLabel color={tooltipData?.data?.color}>
                      {key}
                    </TooltipLabel>
                    <TooltipValue>{tooltipData?.data[key]}</TooltipValue>
                  </React.Fragment>
                );
              }
              return null;
            })}
          </TooltipFiveContainer>
        );
      case 'advanced_campaign_comparison_campaign_analysis':
        return (
          <TooltipFiveContainer>
            <TooltipLabel color={tooltipData?.data?.color}>
              {tooltipData?.data?.label}
            </TooltipLabel>
            <TooltipLabel>Total Results</TooltipLabel>
            <TooltipValue>{tooltipData?.data?.articleCount}</TooltipValue>
            <TooltipLabel>Net Sentiment</TooltipLabel>
            <TooltipValue>{tooltipData?.data?.xValue}%</TooltipValue>
            <TooltipLabel>Reach</TooltipLabel>
            <TooltipValue>
              {addCountPrefix(tooltipData?.data?.yValue)}
            </TooltipValue>
          </TooltipFiveContainer>
        );
      case 'advanced_campaign_monitor_sentiment':
        return (
          <>
            <TooltipThreeContainer>
              <TooltipLabel style={{ flex: '0 0 100%' }}>
                {tooltipData?.rawData?.label}
              </TooltipLabel>
              <div>
                <TooltipLabel color={greenColorGradients.green60}>
                  Positive
                </TooltipLabel>
                <TooltipValue>{tooltipData?.rawData?.pos}</TooltipValue>
              </div>
              <div>
                <TooltipLabel color={redColorGradients.red60}>
                  Negative
                </TooltipLabel>
                <TooltipValue>{tooltipData?.rawData?.neg}</TooltipValue>
              </div>
              <div>
                <TooltipLabel color={coolGrayColorGradients.coolGray60}>
                  Neutral
                </TooltipLabel>
                <TooltipValue>{tooltipData?.rawData?.neu}</TooltipValue>
              </div>
            </TooltipThreeContainer>
          </>
        );
      default:
        return null;
    }
  };

  return <div>{renderTooltip()}</div>;
};

SocialTooltip.propTypes = {
  tooltipData: PropTypes.object,
  widget: PropTypes.object,
};

export default SocialTooltip;
