import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  width: 130px;
`;

const DropdownButton = styled.div`
  border: 1px solid #c1c7cd;
  padding: 5px 8px;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  border-radius: 8px;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #fff;
  min-width: 120px;
  box-shadow: 0px 14px 34px 0px #0000001f;
  z-index: 1;
  border-radius: 6px;
  border: 1px solid #f2f4f8;
`;

const OptionContainer = styled.div`
  padding: 8px 10px;
  display: flex;
  align-items: center;
  margin: 4px;
  cursor: pointer;

  &:hover {
    border-radius: 5px;
    background-color: #f6f2ff;
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  accent-color: #5f39f8;
`;

const OptionLabel = styled.span`
  font-size: 14px;
  color: #333;
  max-width: 65px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SelectAllOption = styled(OptionContainer)`
  background-color: #fff;
  border-radius: 6px 6px 0px 0px;
  margin: 4px;

  &:hover {
    border-radius: 5px;
    background-color: #f6f2ff;
  }
`;

const ChartDropdown = ({
  handleSelectedChartDropdown,
  chartName,
  dashboardKeywords = [],
  selectedChartDropdown = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Transform dashboardKeywords into the required format
  const data = dashboardKeywords?.map((keyword) => ({
    label: keyword,
    value: keyword,
  }));

  // Helper function to get all selected values
  const getSelectedValues = () => {
    const chartOptions = selectedChartDropdown[chartName];
    if (!chartOptions) {
      return data.map((option) => option.value);
    }

    const { brandData, competitionData } = chartOptions;

    // If brandData and competitionData are the same single value, return just one
    if (
      brandData.length === 1 &&
      competitionData.length === 1 &&
      brandData[0] === competitionData[0]
    ) {
      return brandData;
    }

    // Remove duplicates using Set
    return [...new Set([...brandData, ...competitionData])];
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectAll = () => {
    const selectedValues = getSelectedValues();
    if (selectedValues.length === data.length) {
      // If all are selected, select only the first one
      handleSelectedChartDropdown(chartName, [data[0].value]);
    } else {
      // Select all values
      handleSelectedChartDropdown(
        chartName,
        data.map((option) => option.value)
      );
    }
  };

  const handleOptionClick = (option) => {
    const selectedValues = getSelectedValues();

    // Prevent deselecting if only one option is selected
    if (selectedValues.includes(option.value) && selectedValues.length === 1) {
      return;
    }

    let newSelection;
    if (selectedValues.includes(option.value)) {
      newSelection = selectedValues.filter((o) => o !== option.value);
    } else {
      newSelection = [...selectedValues, option.value];
    }

    handleSelectedChartDropdown(chartName, newSelection);
  };

  const selectedValues = getSelectedValues();
  const displayValue =
    selectedValues.length === data.length
      ? 'All'
      : selectedValues.length > 1
      ? `${selectedValues.length} selected`
      : selectedValues[0];

  return (
    <Dropdown ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        {displayValue}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 10L12 15L17 10"
            stroke="#333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </DropdownButton>
      <DropdownContent isOpen={isOpen}>
        <SelectAllOption onClick={handleSelectAll}>
          <Checkbox
            checked={selectedValues.length === data.length}
            onChange={handleSelectAll}
          />
          <OptionLabel>All</OptionLabel>
        </SelectAllOption>
        {data.map((option) => (
          <OptionContainer
            key={option.value}
            onClick={() => handleOptionClick(option)}
          >
            <Checkbox
              checked={selectedValues.includes(option.value)}
              onChange={() => handleOptionClick(option)}
            />
            <OptionLabel>{option.label}</OptionLabel>
          </OptionContainer>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

ChartDropdown.propTypes = {
  handleSelectedChartDropdown: PropTypes.func,
  chartName: PropTypes.string,
  dashboardKeywords: PropTypes.array,
  selectedChartDropdown: PropTypes.object,
};

export default ChartDropdown;
