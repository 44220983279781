import styled from 'styled-components';
import { magentaColorGradients } from '../../constants/graph-colors';

export const MultiTagWrp = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const MultiInputContainer = styled.div`
  padding: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  overflow-y: auto;
  width: 100%;
  height: 7.5rem;
  align-items: center;
  flex-shrink: 0;
  &::-webkit-scrollbar {
    display: none;
  }
  /* ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.addItemContainerColor};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.addItemContainerColor};
  } */
`;

export const Hr = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme?.shadow};
`;

export const RecentTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  height: 15rem;
  &::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.addItemContainerColor};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.addItemContainerColor};
  }
`;

export const Heading = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

export const TagDescp = styled.p`
  font-size: 13px;
  font-weight: 500;
  margin: 0;
`;

export const RecentText = styled.div`
  font-size: ${({ black }) => (black ? '0.75rem' : '1rem')};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ black }) => (black ? '600' : '500')};
  color: ${({ black, theme }) => (black ? theme.text : theme.greyColor)};
`;
export const RecentTagItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  overflow-y: scroll;
  align-items: flex-start;
  width: 100%;
  /* width: 100%; */
  &::-webkit-scrollbar {
    display: none;
  }
  /* ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.addItemContainerColor};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.addItemContainerColor};
  } */
`;

export const TagItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.375rem;
  background: ${({ theme }) => theme.multiTagButton};
  padding: 0.375rem 0.5rem;
  border-radius: 0.5rem;
  /* height: 2rem; */
  cursor: pointer;
`;

export const SelectedTagItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  background: ${magentaColorGradients.magenta60};
  color: white;
  padding: 0.375rem 0.5rem;
  border-radius: 0.5rem;
  /* height: 2rem; */
  cursor: pointer;

  .close-icon {
    display: none;
  }

  &:hover .close-icon {
    display: block;
  }
  p {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
`;

export const CheckBoxWrap = styled.div`
  padding-top: 0.2rem;
  dis &:hover {
    cursor: pointer;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
  padding: 0.75rem;
`;
