import {
  purpleColorGradients,
  cyanColorGradients,
  magentaColorGradients,
  redColorGradients,
  greenColorGradients,
  coolGrayColorGradients,
  yellowColorGradients,
  tealColorGradients,
  blueColorGradients,
} from '../../constants/graph-colors';

import twitterLogo from '../../assets/img/twitterLogo.png';
import redditLogo from '../../assets/img/reddit.png';
import { outletBreakDown } from '../../graphs/utils/graphConst';

export const sentimentChartMapData = {
  title: 'Sentiment',
  subTitle: '',
  component: 'sentiment',
  graphType: 'pie',
  data: {
    title: 'Data Sources',
    subTitle: 'Total Articles',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      widgetName: '',
    },
    data: [
      {
        label: 'Positive',
        value: 50,
        color: greenColorGradients.green50,
      },
      {
        label: 'Negative',
        value: 50,
        color: redColorGradients.red50,
      },
      {
        label: 'Neutral',
        value: 100,
        color: coolGrayColorGradients.coolGray40,
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    legends: [
      {
        label: 'Positive',
        value: 'positive',
        color: greenColorGradients.green50,
      },
      {
        label: 'Negative',
        value: 'negative',
        color: redColorGradients.red50,
      },
      {
        label: 'Neutral',
        value: 'neutral',
        color: coolGrayColorGradients.coolGray40,
      },
    ],
    info: [],
  },
};

export const mediaTypeChartMapData = {
  title: 'Data Sources',
  subTitle: 'Total Articles',
  component: 'media_type',
  graphType: 'donut',
  data: {
    title: 'Data Sources',
    subTitle: 'Total Articles',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      widgetName: '',
    },
    data: [
      {
        label: 'Online',
        value: 50,
        color: purpleColorGradients.purple60,
      },
      // {
      //   label: 'Broadcast',
      //   value: 50,
      //   color: cyanColorGradients.cyan50,
      // },
      {
        label: 'Print',
        value: 100,
        color: purpleColorGradients.purple50,
      },
      {
        label: 'X (Twitter)',
        value: 0,
        color: magentaColorGradients.magenta80,
      },
      {
        label: 'Reddit',
        value: 0,
        color: magentaColorGradients.magenta70,
      },
      {
        label: 'Blogs',
        value: 100,
        color: magentaColorGradients.magenta60,
      },
      {
        label: 'Forums',
        value: 100,
        color: magentaColorGradients.magenta50,
      },
      {
        label: 'Reviews',
        value: 100,
        color: magentaColorGradients.magenta40,
      },
      {
        label: 'YouTube',
        value: 100,
        color: magentaColorGradients.magenta30,
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    legends: [
      {
        label: 'Online',
        value: 'online',
        color: purpleColorGradients.purple60,
      },
      // {
      //   label: 'Broadcast',
      //   value: 'broadcast',
      //   color: cyanColorGradients.cyan50,
      // },
      {
        label: 'Print',
        value: 'print',
        color: magentaColorGradients.magenta50,
      },
      {
        label: 'Blogs',
        value: 'blogs',
        color: tealColorGradients.teal60,
      },
      {
        label: 'Forums',
        value: 'forums',
        color: yellowColorGradients.yellow40,
      },
      {
        label: 'Reviews',
        value: 'reviews',
        color: cyanColorGradients.cyan50,
      },
    ],
    info: [],
  },
};

export const mediaTabData = [
  {
    label: 'All Results',
    value: 'totalArticles',
    count: 0,
  },
  {
    label: 'Online',
    value: 'Online',
    count: 0,
  },
  {
    label: 'Print',
    value: 'Print',
    count: 0,
  },
  {
    label: 'Forums',
    value: 'Forums',
    count: 0,
  },
  {
    label: 'Blogs',
    value: 'Blogs',
    count: 0,
  },
  {
    label: 'Reviews',
    value: 'Reviews',
    count: 0,
  },
  {
    label: 'Reddit',
    value: 'Reddit',
    count: 0,
  },
  {
    label: 'X (Twitter)',
    value: 'X (Twitter)',
    count: 0,
  },
  {
    label: 'YouTube',
    value: 'YouTube',
    count: 0,
  },
];

export const wordCloudChartMapData = {
  title: 'Word Cloud',
  subTitle: '',
  component: 'word_cloud',
  graphType: 'word_cloud',
  data: {
    title: 'Word Cloud',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: 35,
      subLabel: '132/237',
    },
    data: [
      {
        label: 'Damar Hamlin Update',
        value: 90,
        thresholdValue: 13,
        color: '#8A74FF',
      },
      {
        label: 'Airica Steed talks',
        value: 10,
        thresholdValue: 10000,
        color: '#614CFF',
      },
      {
        label: 'NFL Betting Week',
        value: 80,
        thresholdValue: 23,
        color: '#D16BFD',
      },
      {
        label: 'interview NFL Betting',
        value: 75,
        thresholdValue: 92,
        color: '#9955F4',
      },
      {
        label: 'underdogs Multiple ministries',
        value: 66,
        thresholdValue: 40,
        color: '#4337A0',
      },
      {
        label: 'Mavericks finally cover',
        value: 52,
        thresholdValue: 36,
        color: '#85ECE9',
      },
      {
        label: 'USs largest estuary',
        value: 45,
        thresholdValue: 30,
        color: '#32CBC8',
      },
      {
        label: 'Monica Robins',
        value: 45,
        thresholdValue: 80,
        color: '#35C684',
      },
      {
        label: 'Dallas Market',
        value: 40,
        thresholdValue: 79,
        color: '#FA8750',
      },
      {
        label: 'CEO Dr. Airica',
        value: 25,
        thresholdValue: 42,
        color: '#EFB73E',
      },
      {
        label: 'Airica Steed',
        value: 25,
        thresholdValue: 27,
        color: '#FFE566',
      },
      {
        label: 'NFL Betting',
        value: 25,
        thresholdValue: 29,
        color: '#EB4A84',
      },
      {
        label: 'Surprising Launches',
        value: 24,
        thresholdValue: 84,
        color: '#6D5E7B',
      },
      {
        label: 'Launches at Dallas',
        value: 23,
        thresholdValue: 62,
        color: '#656B8A',
      },
      {
        label: 'School Chesapeake Bay',
        value: 22,
        thresholdValue: 39,
        color: '#B4BAD9',
      },
      {
        label: 'Busch Gardens offers',
        value: 21,
        thresholdValue: 19,
        color: '#614CFF',
      },
      {
        label: 'Boston Globe',
        value: 20,
        thresholdValue: 82,
        color: '#8A74FF',
      },
      {
        label: 'roof in Denver',
        value: 20,
        thresholdValue: 71,
        color: '#D16BFD',
      },
      {
        label: 'Jaguars-Titans Report',
        value: 20,
        thresholdValue: 44,
        color: '#9955F4',
      },
      {
        label: 'MetroHealth CEO',
        value: 20,
        thresholdValue: 18,
        color: '#4337A0',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const topSourceChartMapData = {
  title: 'Popular Websites',
  subTitle: '',
  component: 'top_source',
  graphType: 'top_source_bar_tree_map',
  data: {
    title: 'Top Sourcess',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      subLabel: '132/237',
      value: '1.9 Million',
    },
    data: [
      {
        label: 'WSL',
        value: 10000,
        thresholdValue: 80,
        color: '#675EF2',
      },
      {
        label: 'Huffington Post',
        value: 9000,
        thresholdValue: 20,
        color: '#675EF2',
      },
      {
        label: 'Bloomberg',
        value: 8000,
        thresholdValue: 60,
        color: '#675EF2',
      },
      {
        label: 'NY Times',
        value: 6100,
        thresholdValue: 60,
        color: '#675EF2',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const topThemeChartMapData = {
  title: 'Popular Topics',
  subTitle: '',
  component: 'top_themes',
  graphType: 'bubble',
  data: {
    title: 'Popular Topics',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.9 Million',
      subLabel: 'Total',
    },
    data: [
      {
        label: 'Betting Week',
        value: 91,
        thresholdValue: 95,
        color: '#614CFF',
      },
      {
        label: 'Airica Steed',
        value: 92,
        thresholdValue: 20,
        color: '#8A74FF',
      },
      {
        label: 'Nfl Betting',
        value: 93,
        thresholdValue: 59,
        color: '#D16BFD',
      },
      {
        label: 'Surprising Launches',
        value: 94,
        thresholdValue: 27,
        color: '#9955F4',
      },
      {
        label: 'Boston Globe',
        value: 95,
        thresholdValue: 16,
        color: '#4337A0',
      },
      {
        label: 'Jaguars-titans Report',
        value: 96,
        thresholdValue: 54,
        color: '#85ECE9',
      },
      {
        label: 'Metrohealth Ceo',
        value: 97,
        thresholdValue: 56,
        color: '#32CBC8',
      },
      {
        label: 'Steed Talks',
        value: 98,
        thresholdValue: 47,
        color: '#35C684',
      },
      {
        label: 'Underdogs Multiple',
        value: 99,
        thresholdValue: 93,
        color: '#FA8750',
      },
      {
        label: 'Multiple Ministries',
        value: 100,
        thresholdValue: 62,
        color: '#EFB73E',
      },
      {
        label: 'Mavericks Finally',
        value: 101,
        thresholdValue: 11,
        color: '#FFE566',
      },
      {
        label: 'Damar Hamlin',
        value: 102,
        thresholdValue: 35,
        color: '#EB4A84',
      },
      {
        label: 'Hamlin Update',
        value: 95,
        thresholdValue: 44,
        color: '#6D5E7B',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const topAuthorChartMapData = {
  title: 'Top Media Contributors',
  subTitle: '',
  component: 'people_top_author',
  graphType: 'top_author_bar',
  data: {
    title: 'Top Media Contributors',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    data: [
      {
        label: 'Author 1',
        value: 10000,
        thresholdValue: 10000,
        color: '#22B9FF',
      },
      {
        label: 'Author 2',
        value: 8746,
        thresholdValue: 8500,
        color: '#22B9FF',
      },
      {
        label: 'Author 3',
        value: 7984,
        thresholdValue: 7900,
        color: '#22B9FF',
      },
      {
        label: 'Author 4',
        value: 6000,
        thresholdValue: 6000,
        color: '#22B9FF',
      },
      {
        label: 'Author 5',
        value: 6000,
        thresholdValue: 6000,
        color: '#22B9FF',
      },
      {
        label: 'Author 6',
        value: 6000,
        thresholdValue: 6000,
        color: '#22B9FF',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};
export const topInfluencerChartMapData = {
  title: 'Top Influencers',
  subTitle: '',
  component: 'top_influencers',
  graphType: 'top_author_bar',
  data: {
    title: 'Top Influencers',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    header: [
      { label: 'Author Name', value: 'authorName' },
      { label: 'Source', value: 'source' },
      { label: 'Mentions', value: 'mentions' },
      { label: 'Reach', value: 'reach' },
    ],
    data: [],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};
export const topHashtagsChartMapData = {
  title: 'Top Hashtags',
  subTitle: '',
  component: 'top_hashtags',
  graphType: 'top_author_bar',
  data: {
    title: 'Top Hashtags',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    header: [
      { label: 'Hashtag', value: 'hashtag' },
      { label: 'Mentions', value: 'mentions' },
    ],
    data: [],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};
export const topSubredditChartMapData = {
  title: 'Top Subreddits',
  subTitle: '',
  component: 'top_subreddit',
  graphType: 'top_author_bar',
  data: {
    title: 'Top Subreddits',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    header: [
      { label: 'Subreddit Name', value: 'subredditName' },
      { label: 'Total Volume', value: 'totalVolume' },
      { label: 'Posts', value: 'totalPosts' },
      { label: 'Comments', value: 'totalComments' },
      { label: 'Subreddit Subscribers', value: 'subscribers' },
      { label: 'Reddit Score', value: 'totalScore' },
    ],
    data: [],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const resultOverTimeMapData = {
  area: {
    title: 'Results Over Time',
    subTitle: 'Total Articles',
    component: 'result_over_time',
    graphType: 'area',
    data: {
      title: 'Results Over Time',
      subTitle: 'Total Articles',
      summary: {
        subtext: '',
        value: '182',
      },
      data: [
        {
          label: 'May 1',
          value: 28000,
        },
        {
          label: 'May 8',
          value: 10000,
        },
        {
          label: 'May 15',
          value: 15000,
        },
        {
          label: 'May 22',
          value: 10000,
        },
        {
          label: 'May 29',
          value: 15000,
        },
        {
          label: 'Jun 5',
          value: 20000,
        },
        {
          label: 'Jun 12',
          value: 15000,
        },
        {
          label: 'Jun 19',
          value: 20000,
        },
        {
          label: 'Jun 26',
          value: 15000,
        },
        {
          label: 'Jul 2',
          value: 18000,
        },
        {
          label: 'Jul 9',
          value: 15000,
        },
        {
          label: 'Jul 16',
          value: 18000,
        },
        {
          label: 'Jul 22',
          value: 15000,
        },
        {
          label: 'Jul 29',
          value: 18000,
        },
        {
          label: 'Aug 6',
          value: 18000,
        },
      ],
      labels: [
        {
          label: 'label',
          value: 'value',
          color: '#753EBA',
          colorOpacity: '0.2',
          color1: '#C393FF',
          colorOpacity1: '0',
        },
      ],
      info: [],
    },
  },

  column: {
    title: 'Results Over Time',
    subTitle: 'Total Articles',
    component: 'result_over_time',
    graphType: 'result_over_time_column',
    data: {
      title: 'Results Over Time',
      subTitle: 'Total Articles',
      summary: {
        subtext: '',
        value: '182',
      },
      data: [
        {
          label: 'May 1',
          broadcast: '10000',
          print: '10000',
          online: '8000',
        },
        {
          label: 'May 8',
          broadcast: '5000',
          print: '2500',
          online: '2500',
        },
        {
          label: 'May 15',
          broadcast: '5000',
          print: '5000',
          online: '5000',
        },
        {
          label: 'May 22',
          broadcast: '5000',
          print: '2500',
          online: '2500',
        },
        {
          label: 'May 29',
          broadcast: '5000',
          print: '5000',
          online: '5000',
        },
        {
          label: 'Jun 5',
          broadcast: '10000',
          print: '5000',
          online: '5000',
        },
        {
          label: 'Jun 12',
          broadcast: '5000',
          print: '5000',
          online: '5000',
        },
        {
          label: 'Jun 19',
          broadcast: '5000',
          print: '10000',
          online: '5000',
        },
        {
          label: 'Jun 26',
          broadcast: '5000',
          print: '5000',
          online: '5000',
        },
        {
          label: 'Jul 2',
          broadcast: '6000',
          print: '6000',
          online: '6000',
        },
        {
          label: 'Jul 9',
          broadcast: '5000',
          print: '5000',
          online: '5000',
        },
      ],
      labels: [
        {
          label: 'Online',
          value: 'online',
          color: purpleColorGradients.purple60,
        },
        {
          label: 'Print',
          value: 'print',
          color: magentaColorGradients.magenta50,
        },
        {
          label: 'Forums',
          value: 'forum',
          color: yellowColorGradients.yellow40,
        },
        {
          label: 'Blogs',
          value: 'blog',
          color: tealColorGradients.teal60,
        },
        {
          label: 'Reviews',
          value: 'review',
          color: cyanColorGradients.cyan50,
        },
        {
          label: 'X (Twitter)',
          value: 'twitter',
          color: blueColorGradients.blue60,
        },
        {
          label: 'Reddit',
          value: 'reddit',
          color: greenColorGradients.green60,
        },
        {
          label: 'YouTube',
          value: 'YouTube',
          color: magentaColorGradients.magenta70,
        },
      ],
      info: [],
    },
  },
};

export const totalOverviewDetailsMap = [
  {
    title: 'Total Articles',
    data: 0,
    change: 0,
    isIncreased: false,
  },
  {
    title: 'Total Reach',
    data: '-',
    change: '0',
    isIncreased: false,
  },
  {
    title: 'Total AVE',
    data: '-',
    change: '0',
    isIncreased: false,
  },
];

// export const outletBreakdownMapData = {
//   title: 'Media Outlet Breakdown',
//   subTitle: '',
//   component: 'outlet_breakdown',
//   graphType: 'stacked_bar',
//   data: {
//     title: 'Media Outlet Breakdown',
//     subtitle: '2020-04-17',
//     summary: {
//       label: '55%',
//       subLabel: '132/237',
//       data: [
//         {
//           label: 'Online',
//           value: '1100000',
//         },
//         // {
//         //   label: 'Broadcast',
//         //   value: '34000',
//         // },
//         {
//           label: 'Print',
//           value: '800000',
//         },
//         {
//           label: 'Blogs',
//           value: '800000',
//         },
//         {
//           label: 'Forums',
//           value: '800000',
//         },
//         {
//           label: 'Reviews',
//           value: '800000',
//         },
//         {
//           label: 'X (Twitter)',
//           value: '0',
//         },
//         {
//           label: 'Reddit',
//           value: '0',
//         },
//       ],
//     },
//     data: [
//       {
//         label: '',
//         value: [
//           {
//             label: 'online',
//             value: [
//               {
//                 label: 'news',
//                 value: 220,
//               },
//               {
//                 label: 'trade',
//                 value: 340,
//               },
//               {
//                 label: 'wires',
//                 value: 260,
//               },
//             ],
//           },
//           {
//             label: 'print',
//             value: [
//               {
//                 label: 'newspaper',
//                 value: 320,
//               },
//               {
//                 label: 'journals',
//                 value: 340,
//               },
//               {
//                 label: 'magazines',
//                 value: 360,
//               },
//             ],
//           },
//         ],
//       },
//     ],
//     labels: [
//       {
//         label: 'label',
//         value: 'value',
//       },
//     ],
//     legends: [
//       {
//         label: 'Online',
//         value: 'online',
//       },
//       // {
//       //   label: 'Broadcast',
//       //   value: 'broadcast',
//       // },
//       {
//         label: 'Print',
//         value: 'print',
//       },
//       {
//         label: 'Blogs',
//         value: 'blogs',
//       },
//       {
//         label: 'Forums',
//         value: 'forums',
//       },
//       {
//         label: 'Reviews',
//         value: 'reviews',
//       },
//     ],
//     info: [],
//   },
// };
export const outletBreakdownMapData = {
  title: 'Media Outlet Breakdown',
  subTitle: '',
  component: 'outlet_breakdown',
  // graphType: 'stacked_column',
  graphType: 'stacked_bar',
  dataType: ['1d'],
  data: {
    title: 'Media Outlet Breakdown',
    subTitle: 'Total Articles',
    summary: {
      subtext: '',
      label: 'Total Articles',
      value: '0',
    },
    data: [
      {
        label: 'Source 1',
        neg: '190000',
        pos: '360000',
        neu: '310000',
      },
      {
        label: 'Source 2',
        neg: '280000',
        pos: '290000',
        neu: '420000',
      },
      {
        label: 'Source 3',
        neg: '390000',
        pos: '350000',
        neu: '480000',
      },
      {
        label: 'Source 4',
        neg: '250000',
        pos: '280000',
        neu: '350000',
      },
      {
        label: 'Source 5',
        neg: '250000',
        pos: '150000',
        neu: '290000',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};
export const geographicalMapData = {
  title: 'Geographical Breakdown',
  subTitle: '',
  component: 'geographical_breakdown',
  graphType: 'us_map',
  data: {
    title: 'Defect Distribution',
    subtitle: '2020-04-17',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    data: [
      {
        label: '23',
        value: 308.923,
        change: 32.1,
        isIncreased: true,
      },
      {
        label: '25',
        value: 7800.058,
        change: 16.1,
        isIncreased: true,
      },
      {
        label: '26',
        value: 56538.901,
        change: 15.1,
        isIncreased: false,
      },
      {
        label: '30',
        value: 145545.801,
        change: -5.2,
        isIncreased: false,
      },
      {
        label: '32',
        value: 109781.18,
        change: 12.1,
        isIncreased: false,
      },
      {
        label: '34',
        value: 7354.22,
        change: -3.7,
        isIncreased: false,
      },
      {
        label: '36',
        value: 47126.399,
        change: 8.5,
        isIncreased: true,
      },
      {
        label: '37',
        value: 4861.905,
        change: -2.3,
        isIncreased: false,
      },
      {
        label: '39',
        value: 40860.694,
        change: 6.8,
        isIncreased: true,
      },
      {
        label: '42',
        value: 44742.703,
        change: 0.9,
        isIncreased: true,
      },
      {
        label: '44',
        value: 1033.814,
        change: -1.5,
        isIncreased: false,
      },
      {
        label: '47',
        value: 41234.896,
        change: -7.2,
        isIncreased: false,
      },
      {
        label: '48',
        value: 261231.711,
        change: 23.4,
        isIncreased: true,
      },
      {
        label: '49',
        value: 82169.62,
        change: -4.6,
        isIncreased: false,
      },
      {
        label: '53',
        value: 664.521,
        change: 9.3,
        isIncreased: true,
      },
      {
        label: '55',
        value: 54157.805,
        change: -2.8,
        isIncreased: false,
      },
      {
        label: '72',
        value: 3423.775,
        change: -0.3,
        isIncreased: false,
      },
      {
        label: '24',
        value: 9707.241,
        change: 1.2,
        isIncreased: true,
      },
      {
        label: '01',
        value: 50645.326,
        change: -6.5,
        isIncreased: false,
      },
      {
        label: '02',
        value: 570640.95,
        change: 18.7,
        isIncreased: true,
      },
      {
        label: '04',
        value: 11359.084,
        change: -9.1,
        isIncreased: false,
      },
      {
        label: '05',
        value: 52035.477,
        change: 3.8,
        isIncreased: true,
      },
      {
        label: '06',
        value: 155779.22,
        change: -12.3,
        isIncreased: false,
      },
      {
        label: '08',
        value: 103641.888,
        change: 7.6,
        isIncreased: true,
      },
      {
        label: '09',
        value: 4842.355,
        change: -1.8,
        isIncreased: false,
      },
      {
        label: '10',
        value: 948.543,
        change: 0.5,
        isIncreased: true,
      },
      {
        label: '11',
        value: 61.048,
        change: -0.1,
        isIncreased: false,
      },
      {
        label: '12',
        value: 536.759,
        change: 5.4,
        isIncreased: true,
      },
      {
        label: '13',
        value: 57513.485,
        change: -3.9,
        isIncreased: false,
      },
      {
        label: '15',
        value: 6422.628,
        change: 0.7,
        isIncreased: true,
      },
      {
        label: '16',
        value: 82643.117,
        change: -4.2,
        isIncreased: false,
      },
      {
        label: '17',
        value: 5558.93,
        change: 2.1,
        isIncreased: true,
      },
      {
        label: '18',
        value: 35826.109,
        change: -1.5,
        isIncreased: false,
      },
      {
        label: '19',
        value: 55857.13,
        change: 3.9,
        isIncreased: true,
      },
      {
        label: '20',
        value: 81758.717,
        change: -2.6,
        isIncreased: false,
      },
      {
        label: '21',
        value: 39486.338,
        change: 4.8,
        isIncreased: true,
      },
      {
        label: '22',
        value: 43.905,
        change: -1.2,
        isIncreased: false,
      },
      {
        label: '27',
        value: 79626.743,
        change: 6.1,
        isIncreased: true,
      },
      {
        label: '28',
        value: 46923.274,
        change: -2.7,
        isIncreased: false,
      },
      {
        label: '29',
        value: 68741.522,
        change: 7.9,
        isIncreased: true,
      },
      {
        label: '31',
        value: 7682.171,
        change: -3.5,
        isIncreased: false,
      },
      {
        label: '33',
        value: 8952.651,
        change: 1.8,
        isIncreased: true,
      },
      {
        label: '35',
        value: 121298.148,
        change: -5.9,
        isIncreased: false,
      },
      {
        label: '38',
        value: 69000.798,
        change: 3.2,
        isIncreased: true,
      },
      {
        label: '40',
        value: 68594.921,
        change: -2.4,
        isIncreased: false,
      },
      {
        label: '41',
        value: 959.013,
        change: 4.5,
        isIncreased: true,
      },
      {
        label: '45',
        value: 30060.696,
        change: -0.9,
        isIncreased: false,
      },
      {
        label: '46',
        value: 75811,
        change: 2.3,
        isIncreased: true,
      },
      {
        label: '50',
        value: 9216.657,
        change: -1.1,
        isIncreased: false,
      },
      {
        label: '51',
        value: 39490.086,
        change: 2.7,
        isIncreased: true,
      },
      {
        label: '54',
        value: 2403.21,
        change: -3.3,
        isIncreased: false,
      },
      {
        label: '56',
        value: 970300.141,
        change: 5.6,
        isIncreased: true,
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const geographicalWorldMapData = {
  title: 'Geographical Breakdown',
  subTitle: '',
  component: 'geographical_breakdown',
  graphType: 'world_map',
  data: {
    title: 'Defect Distribution',
    subtitle: '2020-04-17',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    data: [
      { label: 'IND', value: 2213 },
      { label: 'USA', value: 24 },
      { label: 'AUS', value: 53 },
      { label: 'NZL', value: 53 },
      { label: 'PAK', value: 5523 },
      { label: 'RUS', value: 1523 },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const dashboardDetails = [];
