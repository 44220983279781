import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';
import { theme } from '../../constants/theme';
import { Button } from '../button';
import {
  ButtonSection,
  CheckBoxWrap,
  Heading,
  Hr,
  MultiInputContainer,
  MultiTagWrp,
  RecentTagItems,
  RecentTagsContainer,
  RecentText,
  SelectedTagItem,
  TagDescp,
  TagItem,
} from './index.sc';
import CheckboxIcon from '../../assets/icons/CheckboxIcon';
import Done from '../../assets/icons/Done';
import {
  AddWordWrp,
  InputWrp,
  KeywordItem,
  KeywordValue,
  KeywordValueInput,
  KeywordWrap,
} from '../search-component/index.sc';
import { Iconwrp } from '../search-result/index.sc';
import CloseIcon from '../../assets/icons/CloseIcon';
import Close from '../../assets/icons/Close';
import { events } from '../../constants/widgets';
import {
  useCreateTagsBySearchId,
  useDeleteArticleTagBySearchId,
} from '../../hooks/useSearch';
import toast from 'react-hot-toast';
import TagCloseIcon from '../../assets/icons/TagCloseIcon';
import AddKeyword from './AddKeyword';
import RadioIcon from '../../assets/icons/RadioboxIcon';
import TickIcon from '../../assets/icons/TickIcon';
import CloseIconCircle from '../../assets/icons/CloseIconCircle';

const MultiTagPopup = ({
  allTheme,
  checked,
  setMultipleTagFlag,
  setMultiTags,
  multiTags,
  articlesInfo,
  setTriggerFetchUseEffect = () => {},
  recentSearchArticlesId,
  storeTags,
  storeArticleCommentsTags,
}) => {
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const initialTags = useRef();
  const [localTags, setLocalTags] = useState([]);
  const { mutateAsync: deleteTag } = useDeleteArticleTagBySearchId();

  useEffect(() => {
    const uniqueTags = getCommonTags(checked, storeTags, articlesInfo);
    initialTags.current = uniqueTags;
    setMultiTags(uniqueTags);
  }, []);

  function getCommonTags(checked, storeTags, articleInfo) {
    // Helper function to get tags from storeTags or fallback to articleInfo
    function getTagsForArticle(articleId) {
      const storeTagObj = storeTags.find(
        (store) => store.article_id === articleId
      );
      if (storeTagObj) {
        return storeTagObj.tags;
      } else {
        const articleInfoObj = articleInfo.find(
          (article) => article.articleId === articleId
        );
        return articleInfoObj ? articleInfoObj.tags : [];
      }
    }

    // Get tags for each checked article
    const tagsList = checked.map(
      (articleId) => new Set(getTagsForArticle(articleId))
    );

    if (tagsList.length === 0) {
      return [];
    }

    // Find common tags across all checked articles
    const commonTags = [...tagsList[0]].filter((tag) =>
      tagsList.every((tagSet) => tagSet.has(tag))
    );

    return commonTags;
  }

  // const [hoverItem, setHoverItem] = useState();
  const [itemIndex, setItemIndex] = useState(false);
  const [editItem, setEditItem] = useState();
  const [singleTagValue, setSingleTagValue] = useState('');
  const { mutateAsync: addTag } = useCreateTagsBySearchId();

  const handleToggle = () => {
    setMultipleTagFlag(false);
    setMultiTags([]);
  };

  const handleChecked = (item, index) => {
    const itemExists = multiTags?.includes(item);
    let updatedMultiTags;
    if (itemExists) {
      updatedMultiTags = multiTags?.filter((tagValue) => tagValue !== item);
    } else {
      updatedMultiTags = [...multiTags, item];
    }
    setMultiTags(updatedMultiTags);
  };

  // const handleEditClick = (item, index) => {
  //   setItemIndex(index);
  //   setEditItem(item);
  //   setSingleTagValue(item);
  // };

  function removeTagAndReturnUpdatedArticles(
    tagToRemove,
    checked,
    storeTags,
    articleInfo
  ) {
    // Helper function to remove the tag from a given tags array
    function removeTag(tags) {
      return tags.filter((tag) => tag !== tagToRemove);
    }

    // Array to store the updated articles
    const updatedArticles = [];

    // Iterate over each articleId in the checked array
    checked.forEach((articleId) => {
      let updatedTags = [];

      // Find and update the storeTags object if it exists
      const storeTagObj = storeTags.find(
        (store) => store.article_id === articleId
      );
      if (storeTagObj) {
        storeTagObj.tags = removeTag(storeTagObj.tags);
        updatedTags = storeTagObj.tags;
      } else {
        // If not found in storeTags, update the articleInfo object
        const articleInfoObj = articleInfo.find(
          (article) => article.articleId === articleId
        );
        if (articleInfoObj) {
          articleInfoObj.tags = removeTag(articleInfoObj.tags);
          updatedTags = articleInfoObj.tags;
        }
      }

      // Push the updated object to the array
      updatedArticles.push({ articleId, tags: updatedTags });
    });

    return updatedArticles;
  }

  const handleRemoveTag = (tag) => {
    try {
      const filteredTag = tag;
      const filteredData = articlesInfo
        ?.map((data, i) => (checked.includes(data?.articleId) ? data : null))
        .filter((data) => data !== null);
      const articleId = filteredData.map((data) => data?.articleId);
      const filteredValue = multiTags?.filter((x, i) => x !== tag);
      const payload = {
        tags: [filteredTag],
        recent_search_id: recentSearchArticlesId,
        article_id: articleId,
      };
      if (initialTags?.current?.includes(tag)) {
        deleteTag?.(payload).then((data) => {
          if (data.isSuccessful) {
            // toast.success('Tag Added');
            setTriggerFetchUseEffect((prev) => !prev);
            setMultiTags(filteredValue);
            toast.success('Tag Removed');
            const updatedData = removeTagAndReturnUpdatedArticles(
              tag,
              checked,
              storeTags,
              articlesInfo
            );
            storeArticleCommentsTags(updatedData, 'AddOrDelete', 'tags', []);
            // storeArticleCommentsTags();
          } else {
            toast.error('Error while removing Tag');
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (value) => {
    setLocalTags((prev) => [...prev, value]);
    setMultiTags((prev) => [...prev, value]);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter' && singleTagValue) {
      const updatedAllData = multiTags?.map((item, ind) => {
        if (ind === index) {
          item = singleTagValue;
        } else {
          return item;
        }
        return item;
      });
      setMultiTags(updatedAllData);
      setEditItem();
    }
  };

  function findAddedAndRemovedTags(initialArray, updatedArray) {
    // Convert arrays to sets for easier comparison
    const initialSet = new Set(initialArray);
    const updatedSet = new Set(updatedArray);

    // Find removed tags (present in initial but not in updated)
    const removedTags = [...initialSet].filter((tag) => !updatedSet.has(tag));

    // Find added tags (present in updated but not in initial)
    const addedTags = [...updatedSet].filter((tag) => !initialSet.has(tag));

    return { removedTags, addedTags };
  }

  const handleBlur = (event, index) => {
    event.key = 'Enter';
    handleKeyDown(event, index);
    setEditItem();
    setSingleTagValue('');
  };

  const changeHandler = (e) => {
    setSingleTagValue(e.target.value);
  };

  function updateTagsForCheckedArticles(
    tagsToRemove,
    tagsToAdd,
    checked,
    storeTags,
    articleInfo
  ) {
    // Helper function to remove specified tags from a given tags array
    function removeTags(tags, tagsToRemove) {
      return tags.filter((tag) => !tagsToRemove.includes(tag));
    }

    // Helper function to add specified tags to a given tags array
    function addTags(tags, tagsToAdd) {
      const tagsSet = new Set(tags); // Use a set to avoid duplicates
      tagsToAdd.forEach((tag) => tagsSet.add(tag));
      return [...tagsSet];
    }

    // Array to store the updated articles
    const updatedArticles = [];

    // Iterate over each articleId in the checked array
    checked.forEach((articleId) => {
      let updatedTags = [];

      // Find and update the storeTags object if it exists
      const storeTagObj = storeTags.find(
        (store) => store.article_id === articleId
      );
      if (storeTagObj) {
        storeTagObj.tags = removeTags(storeTagObj.tags || [], tagsToRemove);
        storeTagObj.tags = addTags(storeTagObj.tags, tagsToAdd);
        updatedTags = storeTagObj.tags;
      } else {
        // If not found in storeTags, update the articleInfo object
        const articleInfoObj = articleInfo.find(
          (article) => article.articleId === articleId
        );
        if (articleInfoObj) {
          articleInfoObj.tags = removeTags(
            articleInfoObj.tags || [],
            tagsToRemove
          );
          articleInfoObj.tags = addTags(articleInfoObj.tags, tagsToAdd);
          updatedTags = articleInfoObj.tags;
        }
      }

      // Push the updated object to the array
      updatedArticles.push({ articleId, tags: updatedTags });
    });

    return updatedArticles;
  }

  const handleSubmit = () => {
    try {
      const filteredData = articlesInfo
        ?.map((data, i) => (checked.includes(data?.articleId) ? data : null))
        .filter((data) => data !== null);
      const articleId = filteredData.map((data) => data?.articleId);
      const payload = {
        tags: multiTags,
        recent_search_id: recentSearchArticlesId,
        article_id: articleId,
      };
      addTag(payload).then((data) => {
        if (data.isSuccessful) {
          if (multiTags?.length > 0) {
            toast.success('Tag Added');
          }
          setTriggerFetchUseEffect((prev) => !prev);

          const { removedTags, addedTags } = findAddedAndRemovedTags(
            initialTags?.current,
            multiTags
          );
          const data = updateTagsForCheckedArticles(
            removedTags,
            addedTags,
            checked,
            storeTags,
            articlesInfo
          );
          storeArticleCommentsTags(data, 'AddOrDelete', 'tags', []);

          storeArticleCommentsTags(payload, 'add', 'tags', filteredData);
        } else {
          toast.error('Error while adding Tag');
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      setMultipleTagFlag(false);
    }
  };

  const onClickLocalTags = (tag) => {
    setMultiTags((prev) => prev.filter((x) => x !== tag));
  };

  const handleDeleteTag = async (tag) => {
    try {
      const payload = {
        tags: [tag],
        article_id: [],
        recent_search_id: recentSearchArticlesId,
      };
      const resp = await deleteTag(payload);
      toast.success(resp?.data?.msg);
    } catch (error) {
      console.log(error);
    } finally {
      //  setStoreTags && setStoreTags([]);
      //  setArticles && setArticles([]);
      //  setPage && setPage(0);
      //  setTriggerFetchTags && setTriggerFetchTags((old) => !old);
    }
  };

  return (
    <MultiTagWrp>
      <Heading black={true} style={{ marginBottom: '32px' }}>
        Add Tags
      </Heading>

      <RecentTagsContainer>
        <TagDescp>Press Enter After Typing New Tags</TagDescp>
        <AddKeyword handleSendKeyword={handleChange} buttonText="New tag" />
        <RecentTagItems>
          {localTags
            ?.filter((tag) => !allTheme.includes(tag))
            ?.map((item, i) => {
              if (multiTags?.includes(item)) {
                return (
                  <SelectedTagItem
                    key={item}
                    onClick={() => {
                      handleChecked(item, i);
                    }}
                  >
                    <div style={{ marginTop: '0.25rem' }}>
                      <TickIcon />
                    </div>
                    <p style={{ fontSize: '12px' }}>{item}</p>
                    <div>
                      <CloseIconCircle
                        onClickIcon={(e) => {
                          e.stopPropagation();
                          setLocalTags((prev) =>
                            prev?.filter((x) => x !== item)
                          );
                        }}
                      />
                    </div>
                  </SelectedTagItem>
                );
              }
              return (
                <TagItem key={item} onClick={() => handleChecked(item, i)}>
                  <CheckBoxWrap>
                    <RadioIcon
                      width="1.5rem"
                      height="1.5rem"
                      color={'white'}
                      borderColor={'#5C5E60'}
                    />
                  </CheckBoxWrap>
                  <RecentText black={true}>{item}</RecentText>
                </TagItem>
              );
            })}
          {allTheme?.map((item, i) => {
            if (multiTags?.includes(item)) {
              return (
                <SelectedTagItem
                  key={item}
                  onClick={() => {
                    handleChecked(item, i);
                    handleRemoveTag(item);
                  }}
                >
                  <div>
                    <TickIcon />
                  </div>
                  <p style={{ fontSize: '12px' }}>{item}</p>
                </SelectedTagItem>
              );
            }
            return (
              <TagItem key={item} onClick={() => handleChecked(item, i)}>
                <CheckBoxWrap>
                  <RadioIcon
                    width="1.5rem"
                    height="1.5rem"
                    color={'white'}
                    borderColor={'#5C5E60'}
                  />
                </CheckBoxWrap>
                <RecentText black={true}>{item}</RecentText>
              </TagItem>
            );
          })}
        </RecentTagItems>
      </RecentTagsContainer>
      <ButtonSection>
        <Button
          title={'Cancel'}
          backgroundColor={theme[selectedTheme].background}
          color={theme[selectedTheme].primary}
          onClick={handleToggle}
          border={theme[selectedTheme].primary}
        />
        <Button
          title={'Done'}
          backgroundColor={theme[selectedTheme].primary}
          onClick={handleSubmit}
        />
      </ButtonSection>
    </MultiTagWrp>
  );
};

export default MultiTagPopup;

MultiTagPopup.propTypes = {
  allTheme: Proptypes.array,
  setMultipleTagFlag: Proptypes.func,
  checked: Proptypes.array,
  allChecked: Proptypes.bool,
  multiTags: Proptypes.array,
  setMultiTags: Proptypes.func,
  articlesInfo: Proptypes.array,
  setTriggerFetchUseEffect: Proptypes.func,
  recentSearchArticlesId: Proptypes.number,
  storeTags: Proptypes.array,
  storeArticleCommentsTags: Proptypes.func,
};
