import React from 'react';
import AiIcon from '../../assets/icons/AI';
import PropTypes from 'prop-types';
import { UberSubtitle, AiLoading, ContentWrp } from './index.sc';

const AiLoader = ({
  loaderText = 'Analyzing Search Results...',
  margin = false,
}) => {
  return (
    <AiLoading margin={margin}>
      <ContentWrp>
        <AiIcon width="43" height="44" />
        <UberSubtitle theme="#8A8A8A">{loaderText}</UberSubtitle>
      </ContentWrp>
    </AiLoading>
  );
};

AiLoader.propTypes = {
  loaderText: PropTypes.string,
  margin: PropTypes.bool,
};

export default AiLoader;
