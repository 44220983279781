import styled from 'styled-components';

export const Tile = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ebebeb;
  background: #fff;
  width: calc(50% - 0.5rem);
  min-height: 104px;
  max-height: 120px;
  width: 30%;
`;

export const TileTitle = styled.div`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.text};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem; /* 120% */
  letter-spacing: -0.01875rem;
  letter-spacing: -0.02rem;
  text-overflow: ellipsis;
`;

export const TileDataWrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
`;

export const TileData = styled.p`
  color: #000;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  letter-spacing: -0.0275rem;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
`;

export const Change = styled.div`
  display: flex;
  align-items: center;
  color: ${({ isIncreased, theme }) =>
    isIncreased ? theme.isIncreasedColor : theme.isDecreasedColor};
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.01875rem;
  background-color: ${({ isIncreased, theme }) =>
    isIncreased
      ? theme.isIncreaseBackGroundColor
      : theme.isDecreasedBackGroundColor};
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
  height: 28px;
  margin-top: 4px;
`;

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  z-index: 0;
`;

export const ChangeWrp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const PercentWrp = styled.span`
  color: #000;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  letter-spacing: -0.0275rem;
`;

// tooltip
export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${({ theme }) => theme.background};
  padding: 10px;
`;
export const TooltipTitle = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 0.5rem;
  color: #585858;
  text-transform: capitalize;
`;
export const TooltipValue = styled.div`
  font-weight: 700;
  font-size: 0.95rem;
  line-height: 0.75rem;
  color: #000000;
`;

// export const ChangeWrp = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 2px;
//   max-width: 160px;
// `;

export const ChangeTxt = styled.div`
  color: ${(theme) => theme.graphAxisColor};
  font-weight: 700;
  font-size: 0.95rem;
  line-height: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1rem;
  width: 12rem;
`;

export const TextWrp = styled.div`
  color: ${({ theme }) => theme.grey100};
  font-weight: 600;
  font-size: 0.813rem;
`;
