import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ConcentricPieGraph from './concentricCircle.graph';
import ResizeHandlerHOC from '../utils/resizeHandlerHOC';

const ConcentricPieChart = ({ data, config, rerender, resetSelection }) => {
  const refElement = useRef(null);
  const graphRef = useRef(null);

  useEffect(() => {
    let graph = graphRef.current;

    if (data) {
      if (!graph) {
        graphRef.current = new ConcentricPieGraph(refElement.current);
        graph = graphRef.current;
      }
      graph.setData(data);
      graph.setConfig({ ...config });
      graph.drawGraph();
    }
  }, [data, config, rerender]);

  useEffect(() => {
    let graph = graphRef.current;

    if (!graph) {
      graphRef.current = new ConcentricPieGraph(refElement.current);
      graph = graphRef.current;
    }
    if (resetSelection) {
      graph.onResetFunc();
    }
  }, [resetSelection]);

  return (
    <div className="concentric-pie-chart graph-container">
      <div className="graph-wrp" ref={refElement}></div>
    </div>
  );
};

ConcentricPieChart.propTypes = {
  data: PropTypes.array,
  config: PropTypes.object,
  rerender: PropTypes.bool,
  resetSelection: PropTypes.bool,
};

export default ResizeHandlerHOC(ConcentricPieChart);
