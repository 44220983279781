import React, { useEffect, useRef, useState } from 'react';
import {
  HalfSlot,
  SlotBody,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotHeader,
  SlotHeaderLeft,
  SlotOverviewWrapper,
  SlotTitle,
} from '../../../components/search-result/index.sc';
import { ConcentricPieChart } from '../../../graphs';
import HelpIcon from '../../../assets/icons/HelpIcon';
import SlotOverview from '../../../components/search-result/slot-details/SlotOverview';
import { SubTitleSmallWrp } from '../../../constants/graph-colors/index.sc';
import ChartToolTip from '../../../components/chart-tool-tip';
import { SlotBodyMain } from '../../../components/advanced-dashboard/author-impact/index.sc';
import Proptypes from 'prop-types';
import PortalTooltip from '../../../components/portal-tooltip';
import SocialTooltip from '../../../components/advanced-dashboard/social-campaign/tooltip';
import Edit2 from '../../../assets/icons/Edit2';
import { Iconwpr } from '../../../components/half-full/index.sc';
import { VerticleDots } from '../../../assets/icons/VerticleDots';
import SimpleReusableDropDown from '../../../components/simple-dropdown';
import { FullSlot } from '../../../components/search-result-v2/index.sc';
import DashboardPopup from '../../../components/dasboard-popup';
import EditGraphPopup from '../../../components/edit-graph-popup';
import { useParams } from 'react-router-dom';

const PopularTopics = ({
  idx,
  selected,
  widget,
  resetSelection,
  handleOnClick,
  type,
  j,
  dashboardType,
  editChart,
  downloadFunction,
  graphDownloading,
  onDownloadChartData,
  selectedComponent,
  setSelectedComponent,
  handleUpdatedChart,
  handleGraphTitleUpdate,
  chartNames,
  customClassName,
}) => {
  const { dashboardId } = useParams();
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [chartToolTip, setChartToolTip] = useState();
  const [openActionDropdown, setOpenActionDropDown] = useState(false);

  const titleRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });
  const [activeChart, setActiveChart] = useState(null);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [editChart2, setEditChart2] = useState({
    chartName: editChart?.chartName || widget?.title,
    chartType: widget?.graphType || '',
  });

  const downloadRef = useRef(null);
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (downloadRef.current && !downloadRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
  };
  const graphData = widget;

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    }, // Replace <Icon1 /> with your actual icon component
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
  ];
  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 24, // Added width of icon and padding
      });
    }
  }, [widget?.data?.data?.title]);

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({
        data: {
          ...tData,
          value: tData?.articleCount,
        },
        rawData: d?.rawData,
      });
      setActiveChart(chartId);
    }
  };

  const handleMouseMove = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };

  const handleMouseLeave = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
      setActiveChart(null);
    }
  };
  const handleOptionIcon = (e, componentName) => {
    // e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown((prev) =>
      prev !== componentName ? componentName : false
    );
  };

  const defaultConfig = (chartId) => {
    const config = {
      handleMouseEnter: (event, d, i) => handleMouseEnter(event, d, i, chartId),
      handleMouseMove: (event, d, i) => handleMouseMove(event, d, i, chartId),
      handleMouseLeave: (event, d, i) => handleMouseLeave(event, d, i, chartId),
      handleOnClick: (event, d) => handleOnClick(event, d, chartId),
    };

    if (dashboardType === 'grid-dashboard') {
      delete config.handleOnClick;
    }

    return config;
  };

  const handleGraphEditClick = (e) => {
    setPopupIsOpen(!popupIsOpen);
  };

  const handleUpdateGraph = async (e, data, updatedChartData) => {
    e.stopPropagation();

    handleUpdatedChart(data);
    const chartData = chartNames?.data?.find(
      (chart) => chart?.default_name === graphData?.title
    );
    handleGraphTitleUpdate(
      updatedChartData,
      data,
      chartData,
      customClassName,
      data?.chartType
    );
    handleGraphEditClick(e);
  };

  const handleEditChart = (data) => {
    setEditChart2(data);
  };

  const cardData = (
    <SlotDetailsMainWrp className={widget.customClassName} ref={containerRef}>
      <SlotDetailsWrp
        hideGap={true}
        peopleTheme={widget?.customClassName === 'people_popular_topic_themes'}
      >
        <SlotHeader className="hide-download" style={{ padding: '0 1rem' }}>
          <SlotHeaderLeft>
            <div>
              <SlotHeaderLeft>
                {' '}
                <SlotTitle ref={titleRef}>
                  {dashboardId
                    ? editChart?.chartName
                    : editChart2?.chartName || 'Popular Topics'}
                </SlotTitle>
                <span
                  style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                  onMouseEnter={() => setChartToolTip(true)}
                  onMouseLeave={() => setChartToolTip(false)}
                >
                  <HelpIcon />
                </span>
              </SlotHeaderLeft>

              {chartToolTip && (
                <ChartToolTip
                  text={widget?.text}
                  dashboardType={true}
                  componentTop={8.5}
                  componentLeft={iconPosition?.left}
                />
              )}
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.data?.data?.summary} />
              </SlotOverviewWrapper>
            </div>
          </SlotHeaderLeft>
          <Iconwpr
            width={'1.5rem'}
            height={'1.5rem'}
            className="hide-downloading"
            onClick={() => handleGraphEditClick(widget)}
          >
            <Edit2 />
          </Iconwpr>
          <div style={{ marginLeft: '5px' }}>
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              onClick={(e) => {
                handleOptionIcon(e, graphData.component);
              }}
              ref={downloadRef}
              className="hide-downloading"
            >
              <VerticleDots
                color={
                  openActionDropdown === selectedComponent
                    ? '#675ef2'
                    : '#5C5E60'
                }
              />
              <SimpleReusableDropDown
                isOpen={openActionDropdown === selectedComponent}
                options={actionDropDownOptions}
                graphDownloading={graphDownloading}
                setIsOpen={setOpenActionDropDown}
              />
            </Iconwpr>
          </div>
        </SlotHeader>
        {/* <div style={{ padding: '0 1rem' }}>
            <SlotOverviewWrapper>
              {widget.show ? (
                <SlotOverview summary={widget?.data?.data?.summary} />
              ) : (
                <SubTitleSmallWrp>--</SubTitleSmallWrp>
              )}
            </SlotOverviewWrapper>
          </div> */}
        <ConcentricPieChart
          data={widget?.data?.data?.data}
          config={{
            ...defaultConfig(widget.customClassName),
          }}
          resetSelection={resetSelection}
        />
        <SlotBody
          type={type}
          className="commentary"
          style={{ padding: '1rem' }}
        >
          <SlotBodyMain>
            {enableTooltip && (
              <PortalTooltip
                isOpen={true}
                pos={toolTipPos}
                align={
                  toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
                }
                vAlign={
                  toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
                }
              >
                <SocialTooltip tooltipData={tooltipData} widget={widget} />
              </PortalTooltip>
            )}
          </SlotBodyMain>
        </SlotBody>
      </SlotDetailsWrp>
      <DashboardPopup
        open={popupIsOpen}
        toggler={handleGraphEditClick}
        width={'40%'}
        popContent={
          <EditGraphPopup
            popupIsOpen={popupIsOpen}
            handleEditClick={handleUpdateGraph}
            handleClose={handleGraphEditClick}
            widgetTitle={editChart?.chartName}
            handleEdit={handleEditChart}
            widget={graphData}
          />
        }
      />
    </SlotDetailsMainWrp>
  );

  return dashboardType === 'grid-dashboard' ? (
    <FullSlot
      className="graph-widget"
      key={`widget-${idx}`}
      selected={idx === selected}
      id={`childDiv-${j}`}
      style={{ height: '27rem' }}
      // onClick={(e) => handleClick(idx, e)}
    >
      {cardData}
    </FullSlot>
  ) : (
    <HalfSlot
      className="graph-widget"
      key={`widget-${idx}`}
      selected={idx === selected}
      id={`childDiv-${j}`}
      // onClick={(e) => handleClick(idx, e)}
    >
      {cardData}
    </HalfSlot>
  );
};

export default PopularTopics;

PopularTopics.defaultProps = {
  type: 'dashboard',
};

PopularTopics.propTypes = {
  idx: Proptypes?.number,
  selected: Proptypes.number,
  widget: Proptypes.object,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  type: Proptypes.string,
  j: Proptypes.number,
  dashboardType: Proptypes.string,
  graphDownloading: Proptypes.string,
  editChart: Proptypes.object,
  downloadFunction: Proptypes.func,
  onDownloadChartData: Proptypes.func,
  selectedComponent: Proptypes.string,
  setSelectedComponent: Proptypes.func,
  handleGraphTitleUpdate: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  chartNames: Proptypes.object,
  customClassName: Proptypes.string,
};
