import styled from 'styled-components';
import BackGround from '../../assets/img/loginImg/LoginBg2.png';

export const TermsContainer = styled.div`
  background-image: url(${BackGround});
  background-size: cover; /* You can use 'cover' to make the background cover the entire container */
  background-position: center; /* Center the background image */
  height: 100vh; /* Set the height to 100% of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TermsBackGroundText = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  color: #fff;
  font-family: Inter;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4rem; /* 133.333% */
  letter-spacing: -0.96px;
  position: absolute;
  bottom: 5rem;
  left: 5rem;
  @media (max-width: 1200px) {
    font-size: 2em;
    line-height: 3rem; /* 133.333% */
  }
`;

export const TermsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50rem;
  max-height: 40rem;
  height: 100%;
  margin: auto;
  padding: 1.5rem;
  gap: 2rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.background};
  z-index: 2;
`;

export const TermsTitleWrp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TitleWrap = styled.div`
  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  text-align: left;
`;

export const CrossIconWrp = styled.div`
  cursor: pointer;
`;

export const TermsContentWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  align-items: center;
  overflow-y: scroll;
  height: 100%;
`;
export const Loaderwrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  width: 100%;
`;
export const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export const ContentText = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: ${({ fontWeight }) => fontWeight || 500};
  line-height: 18px;
  text-align: left;
  width: 100%;
  color: ${({ theme }) => theme.text};
  text-transform: ${({ textTransform }) => textTransform && 'capitalize'};
`;
