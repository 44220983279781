import React from 'react';

function HomeAssist() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      fill="none"
      viewBox="0 0 44 44"
    >
      <rect width="44" height="44" x="0" fill="#5F39F8" rx="22"></rect>
      <mask
        id="mask0_13547_4067"
        style={{ maskType: 'alpha' }}
        width="25"
        height="24"
        x="9"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M9.667 10H33.667V34H9.667z"></path>
      </mask>
      <g mask="url(#mask0_13547_4067)">
        <path
          fill="#fff"
          d="M21.667 32.5c-1.2-1.133-2.575-2-4.125-2.6-1.55-.6-3.175-.9-4.875-.9V18c1.683 0 3.3.304 4.85.913a12.791 12.791 0 014.15 2.637c1.216-1.15 2.6-2.03 4.15-2.637a13.16 13.16 0 014.85-.913v11c-1.717 0-3.346.3-4.888.9a12.578 12.578 0 00-4.112 2.6zm0-2.6a14.454 14.454 0 013.35-1.875c1.183-.467 2.4-.775 3.65-.925v-6.9c-1.217.217-2.413.654-3.588 1.313-1.175.658-2.312 1.537-3.412 2.637-1.1-1.1-2.238-1.98-3.413-2.637-1.175-.659-2.37-1.096-3.587-1.313v6.9c1.25.15 2.466.458 3.65.925 1.183.467 2.3 1.092 3.35 1.875zm0-10.9c-1.1 0-2.042-.392-2.825-1.175-.784-.783-1.175-1.725-1.175-2.825s.391-2.042 1.175-2.825c.783-.783 1.725-1.175 2.825-1.175s2.041.392 2.825 1.175c.783.783 1.175 1.725 1.175 2.825s-.392 2.042-1.175 2.825c-.784.783-1.725 1.175-2.825 1.175zm0-2c.55 0 1.02-.196 1.412-.587.392-.392.588-.863.588-1.413s-.196-1.02-.588-1.412A1.926 1.926 0 0021.667 13c-.55 0-1.021.196-1.413.588A1.926 1.926 0 0019.667 15c0 .55.196 1.02.587 1.413.392.391.863.587 1.413.587z"
        ></path>
      </g>
    </svg>
  );
}

export default HomeAssist;
