import * as d3 from 'd3';
import { setUpEvents } from '../utils/graphEvents';
import {
  magentaColorGradients,
  purpleColorGradients,
  tealColorGradients,
} from '../../constants/graph-colors';

export const concentricPie = function concentricPie() {
  let config = {
    aspectRatio: 1,
    innerRadius: 0.175,
    outerRadius: 0.6,
    secondaryOuterRadius: 1,
    labelPadding: 10,
    opacity: 0.7,
  };

  const colorPalette = [
    tealColorGradients.teal50,
    purpleColorGradients.purple60,
    magentaColorGradients.magenta50,
  ];

  function chart(selection) {
    selection.each(function (data) {
      const container = d3.select(this);
      const containerWidth = container.node().getBoundingClientRect().width;
      const containerHeight = 350;
      const radius = Math.min(containerWidth, containerHeight) / 2;

      const svg = container.selectAll('svg').data([null]);
      const svgEnter = svg.enter().append('svg');
      const svgMerge = svgEnter.merge(svg);

      svgMerge
        .attr('viewBox', `0 0 ${containerWidth} ${containerHeight}`)
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .attr('width', '110%')
        .attr('height', '110%');

      const g = svgMerge.selectAll('g.chart-group').data([null]);
      const gEnter = g.enter().append('g').attr('class', 'chart-group');
      const gMerge = gEnter.merge(g);

      gMerge.attr(
        'transform',
        `translate(${containerWidth / 2},${containerHeight / 2})`
      );

      const pie = d3
        .pie()
        .value((d) => d.value)
        .sort(null);

      const arc = d3
        .arc()
        .innerRadius(radius * config.innerRadius)
        .outerRadius(radius * config.outerRadius);

      // Create groups for primary segments (slice + label)
      const primaryGroups = gMerge.selectAll('.primary-group').data(pie(data));
      const primaryGroupsEnter = primaryGroups
        .enter()
        .append('g')
        .attr('class', (d, i) => `primary-group primary-group-${i}`);
      const primaryGroupsMerge = primaryGroupsEnter.merge(primaryGroups);

      // Primary slices within groups
      primaryGroupsMerge.each(function (d, i) {
        const group = d3.select(this);

        group
          .selectAll('.primary-slice')
          .data([d])
          .enter()
          .append('path')
          .attr('class', `primary-slice primary-slice-${i}`);

        group
          .selectAll('.primary-slice')
          .attr('d', arc)
          .attr('fill', colorPalette[i % colorPalette.length])
          .attr('stroke', 'white');

        // Primary labels within groups
        group
          .selectAll('.primary-label')
          .data([d])
          .enter()
          .append('text')
          .attr('class', `primary-label primary-label-${i}`);

        group
          .selectAll('.primary-label')
          .attr('transform', (d) => {
            const [x, y] = arc.centroid(d);
            const rotation =
              (((d.startAngle + d.endAngle) / 2) * 180) / Math.PI;
            const scaleFactor = 0.6;
            return `translate(${x * scaleFactor},${y * scaleFactor}) rotate(${
              rotation - 90
            })`;
          })
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'start')
          .text((d) => {
            const angleDiff = (d.endAngle - d.startAngle) * (180 / Math.PI);
            if (angleDiff < 10) return '';
            const label = d.data.label;
            return label.length > 6 ? `${label.slice(0, 6)}...` : label;
          })
          .attr('fill', 'white')
          .style('font-size', '12px');

        // Set up events for the primary group elements
        setUpEvents(config, selection, `primary-group-${i}`);
      });

      primaryGroups.exit().remove();

      // Secondary themes with grouped elements
      data.forEach((primary, i) => {
        const secondaryPie = d3
          .pie()
          .value(1)
          .startAngle(pie(data)[i].startAngle)
          .endAngle(pie(data)[i].endAngle);

        const secondaryArc = d3
          .arc()
          .innerRadius(radius * config.outerRadius)
          .outerRadius(radius * config.secondaryOuterRadius);

        const secondaryData = primary.keyword.map((k) => ({
          keyword: k,
          parentData: primary,
        }));

        // Create groups for secondary segments
        const secondaryGroups = gMerge
          .selectAll(`.secondary-group-${i}`)
          .data(secondaryPie(secondaryData));

        const secondaryGroupsEnter = secondaryGroups
          .enter()
          .append('g')
          .attr(
            'class',
            (d, j) =>
              `secondary-group secondary-group-${i} secondary-group-${i}-${j}`
          );

        const secondaryGroupsMerge =
          secondaryGroupsEnter.merge(secondaryGroups);

        // Add slices and labels within each secondary group
        secondaryGroupsMerge.each(function (d, j) {
          const group = d3.select(this);

          // Secondary slice
          group
            .selectAll(`.secondary-slice-${i}`)
            .data([d])
            .enter()
            .append('path')
            .attr(
              'class',
              `secondary-slice secondary-slice-${i} secondary-slice-${i}-${j}`
            );

          group
            .selectAll(`.secondary-slice-${i}`)
            .attr('d', secondaryArc)
            .attr('fill', colorPalette[i % colorPalette.length])
            .attr('fill-opacity', config.opacity)
            .attr('stroke', 'white');

          // Secondary label
          group
            .selectAll(`.secondary-label-${i}`)
            .data([d])
            .enter()
            .append('text')
            .attr(
              'class',
              `secondary-label secondary-label-${i} secondary-label-${i}-${j}`
            );

          group
            .selectAll(`.secondary-label-${i}`)
            .attr('transform', (d) => {
              const [x, y] = secondaryArc.centroid(d);
              const rotation =
                (((d.startAngle + d.endAngle) / 2) * 180) / Math.PI;
              const scaleFactor = 0.8;
              return `translate(${x * scaleFactor},${y * scaleFactor}) rotate(${
                rotation - 90
              })`;
            })
            .attr('text-anchor', 'start')
            .attr('alignment-baseline', 'start')
            .text((d) => {
              const angleDiff = (d.endAngle - d.startAngle) * (180 / Math.PI);
              if (angleDiff < 10) return '';
              const label = d.data.keyword;
              return label.length > 7 ? `${label.slice(0, 7)}...` : label;
            })
            .attr('fill', 'white')
            .style('font-size', '9px');

          // Attach parent data to the group
          const parentData = {
            ...d.data.parentData,
            label: d.data.keyword,
            isKeyword: true,
          };
          group.datum(parentData);

          // Set up events for the secondary group elements
          setUpEvents(config, selection, `secondary-group-${i}-${j}`);
        });

        secondaryGroups.exit().remove();
      });
    });
  }

  chart.config = function (value) {
    if (!arguments.length) return config;
    config = { ...config, ...value };
    return chart;
  };

  return chart;
};
