import React from 'react';
import Proptypes from 'prop-types';
import PurePieGraph from '../../../../graphs/PieChart/graphWrapper';
import {
  Legend,
  LegendDesc,
  LegendIndicator,
  LegendTextWrp,
  LegendWrp,
  LegendsWrp,
  PRImpactDetails,
  PRImpactWrp,
  PRScaleWrp,
  PRTitle,
  PrImpactContainer,
  SubTitle,
  Title,
  ValueContent,
} from './index.sc';

const PRImpactHeader = ({ graphData, dashboardType }) => {
  const needleArcColor = () => {
    if (graphData?.summary?.rawValue < 15) {
      return '#ED3F47';
    } else if (
      graphData?.summary?.rawValue === 15 ||
      graphData?.summary?.rawValue < 40
    ) {
      return '#6FDC8C';
    } else if (
      graphData?.summary?.rawValue === 40 ||
      graphData?.summary?.rawValue < 65
    ) {
      return '#42BE65';
    } else if (
      graphData?.summary?.rawValue === 65 ||
      graphData?.summary?.rawValue > 65
    ) {
      return '#24A148';
    }
  };

  return (
    <PRImpactWrp>
      <PrImpactContainer>
        <PRScaleWrp dashboardType={dashboardType}>
          <PurePieGraph
            data={graphData}
            config={{
              startAngle: (-1 * Math.PI) / 2,
              endAngle: Math.PI / 2,
              enableArcBG: '#F0F2F5',
              arcDividerStrokeColor: 'none',
              innerRadius: 0.15, // 0.01 - 0.5
              arcLabel: true,
              enablePolyline: false,
              enableNeedle: true,
              showInitialLabels: true,
              needleArcColor: needleArcColor(),
              dashboardType: dashboardType === 'overview',
            }}
          />
        </PRScaleWrp>
        <PRImpactDetails>
          <PRTitle>PR Impact Score</PRTitle>
          {graphData?.summary?.rawValue && (
            <ValueContent>{graphData?.summary?.rawValue}%</ValueContent>
          )}
          {/* <Title style={{ opacity: '0' }}>+2% vs previous period</Title> */}
          {/* <SubTitle style={{ opacity: '0' }}>
            Total impact for JAN - SEP, 2021
          </SubTitle> */}
        </PRImpactDetails>
      </PrImpactContainer>
      <LegendsWrp>
        <LegendWrp>
          <LegendIndicator color={'#24A148'} />
          <LegendTextWrp>
            <Legend>EXCELLENT</Legend>
            <LegendDesc>Above 65%</LegendDesc>
          </LegendTextWrp>
        </LegendWrp>
        <LegendWrp>
          <LegendIndicator color={'#42BE65'} />
          <LegendTextWrp>
            <Legend>VERY GOOD</Legend>
            <LegendDesc>Between 40%-65%</LegendDesc>
          </LegendTextWrp>
        </LegendWrp>
        <LegendWrp>
          <LegendIndicator color={'#6FDC8C'} />
          <LegendTextWrp>
            <Legend>GOOD</Legend>
            <LegendDesc>Between 15%-39%</LegendDesc>
          </LegendTextWrp>
        </LegendWrp>
        <LegendWrp>
          <LegendIndicator color={'#FF8C00'} />
          <LegendTextWrp>
            <Legend>POOR</Legend>
            <LegendDesc>Between 1%-14%</LegendDesc>
          </LegendTextWrp>
        </LegendWrp>
        <LegendWrp>
          <LegendIndicator color={'#ED3F47'} />
          <LegendTextWrp>
            <Legend>VERY POOR</Legend>
            <LegendDesc>Below 1%</LegendDesc>
          </LegendTextWrp>
        </LegendWrp>
      </LegendsWrp>
    </PRImpactWrp>
  );
};

export default PRImpactHeader;

PRImpactHeader.propTypes = {
  graphData: Proptypes.object,
  dashboardType: Proptypes.string,
};
