import styled from 'styled-components';

export const LegendWrp = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
`;
export const LegendContainer = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: row;
`;
export const LegendBox = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${({ bgColor }) => bgColor || '#c3c7d9'};
  border-radius: 0.75rem;
`;
export const LegendLabel = styled.div`
  color: #697077;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.875rem; /* 80% */
  letter-spacing: -0.0125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 5rem;
  text-transform: capitalize;
  /* white-space: normal;
  overflow-wrap: break-word; */
`;
