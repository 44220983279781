import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownContainer,
  DropdownList,
  DropdownListItem,
  DropdownOptionsList,
  OptionTitle,
  SearchInput,
  SelectOptionComponent,
  SelectedOption,
} from './index.sc';
import ArrowIcon from './assets/Arrow';

const SelectDropdown = ({
  options = [],
  selectedOption = null,
  handleSelect,
  newWidth,
  searchPlaceholder = 'Select',
}) => {
  const [selected, setSelected] = useState(selectedOption);
  const [show, setShow] = useState(false);
  const selectRef = useRef();

  const handleOptionClick = (option) => {
    setSelected(option);
    handleSelect && handleSelect(option);
  };

  const handleOutsideClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <DropdownContainer ref={selectRef}>
      <SelectOptionComponent
        // active={dropdownOpenStates[0]}
        onClick={(e) => {
          e.preventDefault();
          //   tagsData?.data?.length && setSelectTagsFlag(!selectTagsFlag);
          //   setSelectSaveSearch(false);
          setShow((old) => !old);
        }}
      >
        <SelectedOption>
          {selectedOption?.name || searchPlaceholder}
        </SelectedOption>
        <ArrowIcon />
      </SelectOptionComponent>
      <DropdownOptionsList open={show}>
        {show &&
          options?.map((option, i) => (
            <DropdownListItem
              key={option?.value}
              onClick={(e) => {
                e.preventDefault();
                handleOptionClick(option);
              }}
            >
              <OptionTitle>{option?.name}</OptionTitle>
            </DropdownListItem>
          ))}
      </DropdownOptionsList>
    </DropdownContainer>
  );
};

SelectDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOption: PropTypes.object,
  handleSelect: PropTypes.func.isRequired,
  newWidth: PropTypes.string,
  searchPlaceholder: PropTypes.string,
};

export default SelectDropdown;
