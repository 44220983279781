import React from 'react';

function NavigateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      fill="none"
      viewBox="0 0 45 44"
    >
      <rect width="44" height="44" x="0.333" fill="#5F39F8" rx="22"></rect>
      <circle
        cx="23.833"
        cy="20.5"
        r="4.5"
        stroke="#fff"
        strokeWidth="2"
      ></circle>
      <path
        fill="#fff"
        d="M25.681 23.762H27.681V31.271H25.681z"
        transform="rotate(-45 25.68 23.762)"
      ></path>
      <path
        fill="#fff"
        d="M12.333 19H14.333V24H12.333z"
        transform="rotate(-90 12.333 19)"
      ></path>
      <path
        fill="#fff"
        d="M12.333 24H14.333V29H12.333z"
        transform="rotate(-90 12.333 24)"
      ></path>
      <path
        fill="#fff"
        d="M12.333 29H14.333V38H12.333z"
        transform="rotate(-90 12.333 29)"
      ></path>
    </svg>
  );
}

export default NavigateIcon;
