import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '../../utils';
import {
  Change,
  ChangeTxt,
  ChangeWrp,
  Loader,
  LoadingWrapper,
  TextWrp,
  Tile,
  TileData,
  TileDataWrp,
  TileTitle,
  TooltipTitle,
  TooltipValue,
  TooltipWrapper,
} from './index.sc';
import ArrowIncrease from '../../assets/icons/ArrowIncrease';
import ArrowDecrease from '../../assets/icons/ArrowDecrease';
import PortalTooltip from '../portal-tooltip';
import CircularLoading from '../../assets/icons/loading/circularLoading';
import infoIcon from '../../assets/img/infoIcon.svg';
import ReachTooltip from '../tooltip';
import { coolGrayColorGradients } from '../../constants/graph-colors';
import {
  ChartLabelExtraSmallWrp,
  ChartLabelLargeWrp,
  ChartLabelSmallWrp,
} from '../../constants/graph-colors/index.sc';
const SummaryTooltip = ({
  tooltipData = { label: '', value: '', isIncreased: null, change: '' },
}) => {
  const {
    label = '',
    value = '',
    isIncreased = null,
    change = '',
  } = tooltipData;
  return (
    <TooltipWrapper>
      {/* <TooltipTitle>{label}</TooltipTitle>
      <TooltipValue>
        {label === 'Total AVE' && '~$'}
        {value?.toLocaleString('en-US')}
      </TooltipValue> */}
      <ChangeWrp>
        <Change isIncreased={isIncreased} isToolTip={true}>
          {isIncreased ? <ArrowIncrease /> : <ArrowDecrease />}
          {parseInt(change || 0)?.toLocaleString('en-US')}%{' '}
        </Change>{' '}
        <ChartLabelExtraSmallWrp
          style={{ color: coolGrayColorGradients.coolGray70 }}
        >
          {isIncreased
            ? 'Increase in comparison to previous period'
            : 'Decrease in comparison to previous period'}
        </ChartLabelExtraSmallWrp>
        <div style={{ marginTop: '6px' }}>
          <ChartLabelSmallWrp
            style={{ color: coolGrayColorGradients.coolGray70 }}
          >
            {label === 'Total Articles' ? 'Previous Count' : 'Previous Value'}
          </ChartLabelSmallWrp>
          <ChartLabelLargeWrp style={{ color: 'black', marginTop: '2px' }}>
            {label === 'Total AVE' && '~$'}
            {tooltipData?.previousCount?.toLocaleString('en-US')}
          </ChartLabelLargeWrp>
        </div>
      </ChangeWrp>
    </TooltipWrapper>
  );
};

const OverviewTooltip = ({ tooltipData }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
      <ChartLabelSmallWrp style={{ color: coolGrayColorGradients.coolGray70 }}>
        {tooltipData?.label}
      </ChartLabelSmallWrp>
      <ChartLabelLargeWrp style={{ color: 'black' }}>
        {tooltipData?.label === 'Total AVE' && '~$'}
        {tooltipData?.value?.toLocaleString('en-US')}
      </ChartLabelLargeWrp>
    </div>
  );
};

const UberTiles = ({
  title,
  data,
  isIncreased,
  change,
  handleClick = () => {},
  isLoading = false,
  tile,
}) => {
  const { value, midSuffix } = formatNumber(data, true, true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();

  const [showOverviewTooltip, setShowOverviewTooltip] = useState(false);
  const [overviewToolTipPos, setOverviewToolTipPos] = useState({
    left: 0,
    top: 0,
  });
  const [overviewTooltipData, setOverviewTooltipData] = useState();

  const handleMouseEnter = (event) => {
    setShowTooltip(true);
    setToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
    setTooltipData({
      label: title,
      value: data,
      isIncreased,
      change,
      previousCount: tile?.previousCount,
    });
  };

  const handleMouseMove = (event) => {
    setToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
  };

  const handleMouseLeave = (e) => {
    setToolTipPos({
      left: 0,
      top: 0,
    });
    setTooltipData();
    setShowTooltip(false);
  };

  const handleOverviewMouseEnter = (event) => {
    setShowOverviewTooltip(true);
    setOverviewToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
    setOverviewTooltipData({
      label: title,
      value: data,
      isIncreased,
      change,
      previousCount: tile?.previousCount,
    });
  };

  const handleOverviewMouseMove = (event) => {
    setOverviewToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
  };

  const handleOverviewMouseLeave = (e) => {
    setOverviewToolTipPos({
      left: 0,
      top: 0,
    });
    setOverviewTooltipData();
    setShowOverviewTooltip(false);
  };

  return (
    <Tile onClick={handleClick}>
      <TileDataWrp>
        <TileData
          onMouseEnter={handleOverviewMouseEnter}
          onMouseMove={handleOverviewMouseMove}
          onMouseLeave={handleOverviewMouseLeave}
        >
          {title === 'Total AVE' && value > 0 && '~$'}
          {value || value === 0 ? value : '-'}
          {` ${midSuffix || ''}`}
        </TileData>
        {!(parseInt(tile?.previousCount) === 0 && parseInt(value) === 0) && (
          <Change
            isIncreased={isIncreased}
            isToolTip={false}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            {isIncreased ? <ArrowIncrease /> : <ArrowDecrease />}
            <span>{(parseInt(change) || 0)?.toLocaleString('en-US')}%</span>
          </Change>
        )}
      </TileDataWrp>
      <TileTitle>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
          <TextWrp
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              onMouseEnter={handleOverviewMouseEnter}
              onMouseMove={handleOverviewMouseMove}
              onMouseLeave={handleOverviewMouseLeave}
            >
              {title}
            </div>
            {title === 'Total Reach' && (
              <ReachTooltip
                content="Reach estimates the potential viewership of any particular result based on the number of visitors to the specific source on both desktop and mobile"
                direction="right"
              >
                <img
                  src={infoIcon}
                  alt="info"
                  style={{ marginLeft: '4px', marginTop: '3px' }}
                />
              </ReachTooltip>
            )}
          </TextWrp>

          {isLoading && (
            <LoadingWrapper>
              <Loader size="0.25rem" width="0.8rem" height="0.8rem" />
            </LoadingWrapper>
          )}
        </div>
      </TileTitle>
      {showTooltip && (
        <PortalTooltip
          isOpen={true}
          pos={toolTipPos}
          align={toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'}
          vAlign={'top'}
        >
          <SummaryTooltip tooltipData={tooltipData} />
        </PortalTooltip>
      )}
      {showOverviewTooltip && (
        <PortalTooltip
          isOpen={true}
          pos={overviewToolTipPos}
          align={
            overviewToolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
          }
          vAlign={'top'}
          boxShadow={'0px 8px 20px 0px rgba(0, 0, 0, 0.12)'}
        >
          <TooltipWrapper>
            <OverviewTooltip tooltipData={overviewTooltipData} />
          </TooltipWrapper>
        </PortalTooltip>
      )}
    </Tile>
  );
};

UberTiles.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isIncreased: PropTypes.bool,
  change: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClick: PropTypes.bool,
  isLoading: PropTypes.bool,
  tile: PropTypes.object,
};

SummaryTooltip.propTypes = {
  tooltipData: PropTypes.object,
};

OverviewTooltip.propTypes = {
  tooltipData: PropTypes.object,
};
export default UberTiles;
