import styled from 'styled-components';

export const UberSubtitle = styled.p`
  color: ${({ theme }) => theme};
  font-size: 0.81rem;
  font-family: Inter;
  font-weight: 600;
  margin: 0.25rem 0rem 0rem 0rem;
  padding: 0;
  white-space: nowrap;
`;

export const ContentWrp = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AiLoading = styled.div`
  margin: ${({ margin }) => (margin ? '' : '4rem auto')};
  font-size: ${({ size }) => size};
  position: relative;
`;
