import React from 'react';

function HomeAiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      fill="none"
      viewBox="0 0 44 44"
    >
      <rect width="44" height="44" x="0" fill="#5F39F8" rx="22"></rect>
      <path
        stroke="#fff"
        strokeWidth="2"
        d="M19.989 18.962l.51-1.381.512 1.38a8 8 0 004.726 4.727l1.381.511-1.38.511a8 8 0 00-4.727 4.727l-.511 1.38-.511-1.38a8 8 0 00-4.727-4.727l-1.38-.51 1.38-.512a8 8 0 004.727-4.726z"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="1.35"
        d="M25.76 15a5.175 5.175 0 002.59-2.59A5.176 5.176 0 0030.94 15a5.176 5.176 0 00-2.59 2.59A5.175 5.175 0 0025.76 15z"
      ></path>
    </svg>
  );
}

export default HomeAiIcon;
