import React from 'react';

const Minus = () => {
  return (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.60039 1.99951L0.400391 1.99951L0.400391 -0.000488281L9.60039 -0.000488683L9.60039 1.99951Z"
        fill="#C1C7CD"
      />
    </svg>
  );
};

export default Minus;
