import PropTypes from 'prop-types';
import React from 'react';

const ErrorIcon = ({ width = '28', height = '28' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M14 20.666C14.3778 20.666 14.6944 20.5382 14.95 20.2827C15.2055 20.0271 15.3333 19.7105 15.3333 19.3327C15.3333 18.9549 15.2055 18.6382 14.95 18.3827C14.6944 18.1271 14.3778 17.9993 14 17.9993C13.6222 17.9993 13.3055 18.1271 13.05 18.3827C12.7944 18.6382 12.6667 18.9549 12.6667 19.3327C12.6667 19.7105 12.7944 20.0271 13.05 20.2827C13.3055 20.5382 13.6222 20.666 14 20.666ZM12.6667 15.3327H15.3333V7.33268H12.6667V15.3327ZM14 27.3327C12.1555 27.3327 10.4222 26.9827 8.79999 26.2827C7.17777 25.5827 5.76666 24.6327 4.56666 23.4327C3.36666 22.2327 2.41666 20.8216 1.71666 19.1993C1.01666 17.5771 0.666656 15.8438 0.666656 13.9993C0.666656 12.1549 1.01666 10.4216 1.71666 8.79935C2.41666 7.17713 3.36666 5.76602 4.56666 4.56602C5.76666 3.36602 7.17777 2.41602 8.79999 1.71602C10.4222 1.01602 12.1555 0.666016 14 0.666016C15.8444 0.666016 17.5778 1.01602 19.2 1.71602C20.8222 2.41602 22.2333 3.36602 23.4333 4.56602C24.6333 5.76602 25.5833 7.17713 26.2833 8.79935C26.9833 10.4216 27.3333 12.1549 27.3333 13.9993C27.3333 15.8438 26.9833 17.5771 26.2833 19.1993C25.5833 20.8216 24.6333 22.2327 23.4333 23.4327C22.2333 24.6327 20.8222 25.5827 19.2 26.2827C17.5778 26.9827 15.8444 27.3327 14 27.3327Z"
        fill="#BF2B2B"
      />
    </svg>
  );
};

export default ErrorIcon;

ErrorIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
