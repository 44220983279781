import styled from 'styled-components';

export const PRImpactWrp = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  /* padding: 0 1rem; */
`;

export const PRImpactDetails = styled.div`
  /* position: absolute; */
  /* top: 15%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  gap: 0.25rem;
  /* margin-top: -1.5rem; */
`;

export const PRTitle = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme.prImpactLabel};
`;

export const ValueContent = styled.div`
  font-weight: 600;
  font-size: 2rem;
  font-family: Inter;
`;

export const Title = styled.div`
  font-size: 0.75rem;
`;

export const SubTitle = styled.div`
  font-size: 0.5rem;
  color: #585858;
`;

export const LegendsWrp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  border-top: 1px solid ${({ theme }) => theme.graphColors?.coolGray20};
`;
export const LegendWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.375rem;
`;

export const LegendTextWrp = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.div`
  font-weight: 600;
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.graphColors.coolGray60};
  display: flex;
  align-items: center;
`;
export const LegendIndicator = styled.span`
  background-color: ${({ color = '' }) => color};
  font-weight: bold;
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 2px;
  box-shadow: ${({ color = '' }) => `0px 3px 5px -1px ${color} `};
`;
export const LegendDesc = styled.div`
  font-weight: 600;
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.graphColors.coolGray60};
`;

export const PrImpactContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
`;

export const PRScaleWrp = styled.div`
  /* display: flex;
  align-items: flex-start !important; */
  /* padding-top: 3.5rem; */
  /* padding-right: ${({ dashboardType }) =>
    dashboardType !== 'overview' && '1.5rem'}; */
  width: 100%;
  height: 100%;
  /* margin-left: ${({ dashboardType }) =>
    dashboardType === 'overview' && '1rem'}; */
  position: relative;
  margin-left: 0.5rem;
  @media (min-width: 950px) and (max-width: 1250px) {
    margin-left: ${({ dashboardType }) =>
      dashboardType === 'overview' ? '1rem' : '1rem'};
  }
  @media (max-width: 950px) {
    margin-left: ${({ dashboardType }) =>
      dashboardType === 'overview' ? '2rem' : '1.5rem'};
  }

  /* @media (min-width: 1250px) and (max-width: 1450px) {
    margin-left: ${({ dashboardType }) =>
    dashboardType === 'overview' ? '2rem' : '1rem'};
    padding-right: 1rem;
  } */
`;
