import { useEffect, useState } from 'react';
import {
  get,
  axiosPost,
  axiosDel,
  axiosUpdate,
  axiosPutRequestAPI,
} from '../service';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { API, getAuthHeaders } from '../constants';
import { objectToQueryString } from './useSearch';

// Get subscription history
const getSubscriptionHistoryQueryData = (orgId) => {
  const authHeaders = getAuthHeaders();
  return get(`${API}/setting-subscription/history?org_id=${orgId}`, {
    ...authHeaders,
  });
};

export const useGetSubscriptionHistoryQueryData = (orgId) => {
  return useQuery({
    queryKey: ['subscription-history-data', orgId],
    queryFn: () =>
      getSubscriptionHistoryQueryData(orgId).then((response) => response.data),
    enabled: orgId !== null,
    refetchOnWindowFocus: false,
  });
};

// Get user data
const getUserQueryData = (userId) => {
  const authHeaders = getAuthHeaders();
  return get(`${API}/setting-user/get-user/${userId}`, {
    ...authHeaders,
  });
};

export const useGetuserQueryData = (userId) => {
  return useQuery({
    queryKey: ['user-data', userId],
    queryFn: () => getUserQueryData(userId).then((response) => response.data),
    enabled: userId !== null,
    refetchOnWindowFocus: false,
  });
};

// get all client users
const getAllClientUsers = ({ pageParam = 1, query }) => {
  const authHeaders = getAuthHeaders();
  const queryString = objectToQueryString({
    ...query,
    page: pageParam,
  });
  return get(`${API}/setting-organization/users?${queryString}`, {
    ...authHeaders,
  });
};

export const useGetAllClientUsers = (query) => {
  return useInfiniteQuery({
    queryKey: ['get-all-users', query],
    queryFn: ({ pageParam = 1 }) => getAllClientUsers({ pageParam, query }),
    getNextPageParam: (lastPage, allPages) => {
      // Return the next page number, adjust if necessary
      return allPages.length + 1;
    },
    enabled: !!query?.org_id,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
};

// create client user
const createClientUser = (newUser) => {
  return axiosPost(
    `${API}/setting-organization/create-organization-user`,
    newUser,
    {}
  );
};

export const useCreateClientUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newUser) => createClientUser(newUser),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-all-users']);
    },
  });
};

// update client user
const updateClentUser = async (user) => {
  const response = await axiosUpdate(
    `${API}/setting-organization/update-organization-user`,
    user,
    {}
  );
  return response;
};

export const useUpdateClientUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user) => updateClentUser(user),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-all-users']);
    },
  });
};

// delete client user
const deleteClientUser = (ids) => {
  return axiosDel(
    `${API}/setting-organization/delete-organization-user`,
    {},
    ids
  );
};

export const useDeleteClientUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (ids) => deleteClientUser(ids),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-all-users']);
    },
    onError: (err) => {
      throw new Error(err || 'Error Deleting User');
    },
  });
};

// Update user data
const updateUserData = (userId, payload) => {
  return axiosUpdate(
    `${API}/setting-user/user-update?id=${userId}`,
    payload,
    {}
  );
};

export const useUpdateUserData = (userId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => updateUserData(userId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-all-users']);
    },
  });
};

// Update user data
const updateProfilePic = (userId, payload) => {
  return axiosPutRequestAPI(
    '/settings/profile_Picture_update/',
    { id: userId },
    payload
  );
};

export const useUpdateProfilePic = (userId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => updateProfilePic(userId, payload),
    // onSuccess: () => {
    //   queryClient.invalidateQueries(['user-data']);
    // },
  });
};

const getOrgUsersDropDown = async (payload) => {
  const { data: response } = await get(
    `${API}/setting-organization/users-dropdown`,
    {}
  );
  return response;
};

export const useOrgUsersDropDown = () => {
  return useQuery({
    queryKey: ['setting-organization-users'],
    queryFn: () => getOrgUsersDropDown(),
    refetchOnWindowFocus: false,
    // enabled: !editMode,
  });
};

const createContactTicket = (newUser) => {
  return axiosPost(`${API}/zoho-desk/create-ticket`, newUser, {});
};

export const useCreateContactTicket = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newUser) => createContactTicket(newUser),
  });
};

// update terms and condition
const updateTermsCondition = (payload) => {
  return axiosUpdate(`${API}/setting-user/update_term-status`, payload, {});
};

export const useUpdateTermsCondition = (userId) => {
  return useMutation({
    mutationFn: updateTermsCondition,
    // onSuccess: () => {
    //   queryClient.invalidateQueries(['get-all-users']);
    // },
  });
};

// Get Terms and conditions status history
const getTermsConditionStatus = (payload) => {
  return get(`${API}/setting-user/terms-conditions?email=${payload}`);
};

export const useGetTermsConditionStatus = () => {
  return useMutation({ mutationFn: getTermsConditionStatus });
};
