import React, { useState } from 'react';
import {
  ContentText,
  CoverupWrp,
  CutIcon,
  HeadingContainer,
  HeadingContainerHeading,
  // HeadingContainerHeadingtwo,
  // HeadingContainertwo,
  MajorDetails,
  MajorDetailsHeading,
  MajorDetailsHeadingContainer,
  MajorDetailsText,
  Mastercontainer,
  TermsButtonWrap,
  TermsConditionsContentWrp,
  Unorderedlist,
  UnorderedlistItems,
  UpdationInfo,
  UpdationInfoBold,
  UpdationInfoNormal,
} from './index.sc';
import X from '../../assets/icons/X';

// import AppBG from '../app-bg';
// import bgsr from '../../assets/img/bg/bg_sr.svg';
// import AppHeader from '../app-header';
// import AppFooter from '../app-footer';

// Used for navigation
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '../button';
import { theme } from '../../constants/theme';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useUpdateTermsCondition } from '../../hooks/useSettings';
import toast from 'react-hot-toast';
import { getTokenData } from '../../constants/validateToken';
import { logAwsRumEvent } from '../../utils';
import { eventNames } from '../../constants';

// import { CrossButtonWrp } from '../../pages/news-letter/newsletter-top-section/top-bar/index.sc';
// import ArrowLeft from '../../assets/icons/ArrowLeft';

export default function TermsAndConditions({
  updateParentState,
  heading,
  content,
  emailID,
  setIsTermsAgreed,
}) {
  // the onclick funtion to navigate
  // const navigate = useNavigate();
  // const handleButtonClick = () => {
  //   updateParentState(false);
  // };

  const [btnTxt, setBtnTxt] = useState('Agree and Proceed');

  const { mutateAsync: updateTermsStatus } = useUpdateTermsCondition();

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const handleSubmit = async () => {
    setBtnTxt('Please wait...');
    try {
      const termResp = await updateTermsStatus({
        email: emailID,
        terms_status: 'True',
      });
      if (termResp?.isSuccessful) {
        setIsTermsAgreed(true);
      } else {
        toast.error(
          'The request was not submitted successfully. Please try again.'
        );
      }
    } catch (error) {
      toast.error('An error occurred during the API call.');
      const tokenData = getTokenData();
      logAwsRumEvent(eventNames.function_error_handled_log, {
        error: error?.stack,
        type: 'crash detected',
        id: tokenData?.user_id || 'not logged in',
        name: error.name,
      });
      Sentry.captureException(error);
    } finally {
      setBtnTxt('Agree and Proceed');
    }
  };

  return (
    <>
      {/* <AppBG bg1h={'11.5%'} bg1={bgsr} />
      <AppHeader /> */}
      <CoverupWrp>
        <Mastercontainer>
          <HeadingContainer>
            {/* <CrossButtonWrp onClick={handleButtonClick}>
              <ArrowLeft color={'#656B8A'} width="2rem" />
            </CrossButtonWrp> */}
            <HeadingContainerHeading>{heading}</HeadingContainerHeading>
            {/* <CutIcon onClick={handleButtonClick}>
              <X color="#cdcdcd"></X>
            </CutIcon> */}
          </HeadingContainer>
          {/* <HeadingContainertwo>
            <HeadingContainerHeadingtwo>
              Privacy Policy
            </HeadingContainerHeadingtwo>
          </HeadingContainertwo> */}
          {/* <UpdationInfo>
            <UpdationInfoBold>Last updated: January 24, 2023</UpdationInfoBold>
            <UpdationInfoNormal>
              This Privacy Policy includes important information about your
              personal data and we encourage you to read it carefully.
            </UpdationInfoNormal>
          </UpdationInfo>
          <Unorderedlist>
            <UnorderedlistItems>
              Personal Data that we collect and how we use and share it
            </UnorderedlistItems>
            <UnorderedlistItems>
              More ways we collect, use and share Personal Data
            </UnorderedlistItems>
            <UnorderedlistItems>
              Legal bases for processing data
            </UnorderedlistItems>
            <UnorderedlistItems>Your rights and choices</UnorderedlistItems>
            <UnorderedlistItems>Security and retention</UnorderedlistItems>
            <UnorderedlistItems>
              PInternational data transfers
            </UnorderedlistItems>
            <UnorderedlistItems>Updates and notifications</UnorderedlistItems>
            <UnorderedlistItems>
              Jurisdiction-specific provisions
            </UnorderedlistItems>
            <UnorderedlistItems>Contact us</UnorderedlistItems>
          </Unorderedlist> */}
          {/* <MajorDetails>
            <MajorDetailsHeadingContainer>
              <MajorDetailsHeading>
                Personal Data that we collect and how we use and share
              </MajorDetailsHeading>
            </MajorDetailsHeadingContainer>
            <MajorDetailsText>
              This report may contain links to external or third-party websites.
              These links are provided solely for your convenience. Links taken
              to other sites are done so at your own risk, and InfoVision, Inc.
              and its affiliates, “AlphaMetricx”, accept no liability for any
              linked sites or their content. AlphaMetricx makes no warranties or
              representations, express or implied about such linked websites,
              the third parties they are owned and operated by, the information
              contained on them or the suitability or quality of any of their
              products or services. AlphaMetricx does not authorize the
              infringement of any intellectual property rights contained in
              material offered through these linked sites. Please refer to the
              use agreement and/or copyright statements of any external site you
              visit, or the terms and conditions of any externally provided web
              site for instructions, restrictions, and guidelines. If you have a
              question, please contact the webmaster of the external site.
            </MajorDetailsText> */}
          {/* <MajorDetailsHeadingContainer>
              <MajorDetailsHeading>Title</MajorDetailsHeading>
            </MajorDetailsHeadingContainer>
          {/* </MajorDetails> */}
          <TermsConditionsContentWrp>
            {Object.entries(content)?.map(([keys, value], id) => (
              <React.Fragment key={id}>
                <ContentText fontWeight={700} textTransform={true}>
                  {keys?.replace(/_/g, ' ')}
                </ContentText>
                <ContentText>{value}</ContentText>
              </React.Fragment>
            ))}
          </TermsConditionsContentWrp>
          <TermsButtonWrap>
            <Button
              title={'Disagree'}
              backgroundColor={theme[selectedTheme].background}
              color={theme[selectedTheme].primary}
              onClick={() => {
                localStorage.clear();
                Sentry.setUser(null);
                window.location.reload();
              }}
              border={theme[selectedTheme].primary}
            />
            <Button
              title={btnTxt}
              backgroundColor={theme[selectedTheme].primary}
              onClick={handleSubmit}
            />
          </TermsButtonWrap>
        </Mastercontainer>
      </CoverupWrp>
      {/* <AppFooter /> */}
    </>
  );
}

TermsAndConditions.propTypes = {
  updateParentState: PropTypes.func,
  heading: PropTypes.string,
  content: PropTypes.string,
  emailID: PropTypes.string,
  setIsTermsAgreed: PropTypes.func,
};
